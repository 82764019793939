import React from 'react';
import { Calendar } from 'antd';
import "tailwindcss/tailwind.css";

const Agenda: React.FC = () => {
  return (
    <div style={{ padding: 16}}>
      <Calendar fullscreen={false} />
    </div>
  );
};

export default Agenda;
