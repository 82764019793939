import React, { useState, useEffect } from "react";
import { Modal, Button, Input } from "antd";
import IconClosed from "../../../../assets/icons/IconClosed.svg";
import IconSearch from "../../../../assets/icons/search.svg";
import ModalSaved from "./ModalSavedChanges";

interface ModalAddSkillsProps {
  visible: boolean;
  onClose: () => void;
  onAdd: (newSkill: string) => void;
}

const ModalAddSkills: React.FC<ModalAddSkillsProps> = ({ visible, onClose, onAdd }) => {
  const [newSkill, setNewSkill] = useState("");
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [isModalSavedVisible, setIsModalSavedVisible] = useState(false); // Estado para controlar la visibilidad de ModalSaved

  useEffect(() => {
    setIsSubmitDisabled(newSkill.length === 0);
  }, [newSkill]);

  const handleSaveSkill = () => {
    if (!isSubmitDisabled) {
      onAdd(newSkill);
      setIsModalSavedVisible(true); // Mostrar el modal ModalSaved
    }
  };

  const handleModalSavedClose = () => {
    setIsModalSavedVisible(false); // Cerrar el modal ModalSaved
    onClose(); // Cerrar el modal actual después de guardar
  };

  return (
    <>
      <Modal
        open={visible}
        onCancel={onClose}
        footer={null}
        centered
        closable={false}
        width={677}
        bodyStyle={{ borderRadius: "12px" }}
        style={{ borderRadius: "12px", border: "1px solid #E1E1E2", marginTop: "15px", marginBottom: "15px" }}
      >
        <img
          src={IconClosed}
          alt="Cerrar"
          onClick={onClose}
          style={{
            position: "absolute",
            top: "34px",
            right: "34px",
            cursor: "pointer",
            width: "24px",
            height: "24px",
          }}
        />

        <div className="text-center mx-[86px] mb-[36px] mt-[20px]">
          <h3 className="mt-[16px] mx-[44px] text-heading-md font-bold">
            Añadir habilidades
          </h3>
          <p className="font-medium px-[55px] text-body-sm mt-[10px]">
            Por favor, a continuación ingresa la habilidad que deseas añadir
          </p>
        </div>

        <div className="mx-[86px] mb-[32px] mt-[24px]">
          <span className="text-body-md text-[#757575] font-medium">
            Habilidad
          </span>

          <Input
            prefix={<img src={IconSearch} width={24} height={24} />}
            placeholder="Habilidad (ej, trabajo en equipo)"
            value={newSkill}
            onChange={(e) => setNewSkill(e.target.value)}
            className="Input-Filter-Employment w-full mt-[16px] rounded-[12px] custom-input-company px-2"
          />

          <div className="flex justify-center mt-[40px]">
            <Button
              onClick={onClose}
              className="principal-nav-notify-buttonG w-[118px] h-[44px]"
              style={{ marginRight: "8px", borderRadius: "4px" }}
            >
              Cancelar
            </Button>
            <Button
              disabled={isSubmitDisabled}
              onClick={handleSaveSkill}
              className={`w-[181px] h-[44px] ml-[18px] rounded-[4px] ${isSubmitDisabled ? "bg-[#F4F4F5] text-[#757575] font-semibold text-body-md cursor-not-allowed" : "bg-blue3 principal-nav-notify-button2 text-white cursor-pointer"}`}
            >
              Guardar cambios
            </Button>
          </div>
        </div>
      </Modal>

      {/* ModalSaved */}
      <ModalSaved
        visible={isModalSavedVisible}
        onClose={handleModalSavedClose}
      />
    </>
  );
};

export default ModalAddSkills;
