import React, { useState } from 'react';
import type { DatePickerProps } from 'antd';
import { DatePicker, Input } from 'antd';

interface CustomDatePickerProps {
  useInput?: boolean; // Prop para determinar si se usa un Input separado
}

const CustomDatePicker: React.FC<CustomDatePickerProps> = ({ useInput = false }) => {
  const [selectedDate, setSelectedDate] = useState<any>(null);

  const handleDateChange: DatePickerProps['onChange'] = (date, dateString) => {
    setSelectedDate(dateString); // Guardar la fecha seleccionada como cadena
  };

  return (
    <div className="flex items-center space-x-[21px]">
      {useInput ? (
        <>
          <DatePicker
            onChange={handleDateChange}
            style={{
              width: '191px',
              height: '44px',
              borderRadius: '4px',
              borderColor: '#91c3fd', // Color de borde celeste
            }}
            className="
              custom-date-picker
              p-2
              text-heading-x1
              font-medium
            "
          />
          <Input
            value={selectedDate || ''}
            onChange={(e) => setSelectedDate(e.target.value)}
            style={{
              width: '278px',
              height: '44px',
              borderRadius: '4px 4px 0 0', // Esquinas superiores redondeadas
              borderBottom: '3px solid #999999', // Borde inferior gris
              backgroundColor: '#E6E0E9',
              color: '#000',
              paddingLeft: '8px',
              paddingRight: '8px',
              paddingTop: '8px',
              paddingBottom: '8px',
            }}
            placeholder="Fecha actual"
          />
        </>
      ) : (
        <DatePicker
          onChange={handleDateChange}
          style={{
            width: '191px',
            height: '44px',
            borderRadius: '4px',
            borderColor: '#91c3fd',
            fontSize: '18px',
            fontWeight: 500,
          }}
          className="
            custom-date-picker
            p-2
          "
        />
      )}

      <style>
        {`
          .custom-date-picker .ant-picker-input > input {
            height: 100%; // Asegura que el input llene todo el contenedor
            color: #000;
          }

          .custom-date-picker .ant-picker {
            width: 191px;
            height: 44px;
            border-radius: 4px;
            border-color: #91c3fd;
            padding: 0 10px;
            transition: all 0.2s ease;
          }

          .custom-date-picker .ant-picker:hover {
            border-color: #91c3fd;
          }

          .custom-date-picker .ant-picker-focused {
            border-color: #91c3fd;
            box-shadow: 0 0 0 2px rgba(145, 195, 253, 0.3); /* Sombra azul alrededor */
          }
        `}
      </style>
    </div>
  );
};

export default CustomDatePicker;
