import React, { useState } from "react";
import { Input, Row, Col, Select } from "antd";
import IconSearch from "../../assets/icons/search.svg";
import IconLocation from "../../assets/icons/location.svg";
import IconRecommendations from "../../assets/icons/recommendations.svg";
import IconAlerts from "../../assets/icons/alerts.svg";
import IconResults from "../../assets/icons/results.svg";
import { useNavigate, Routes, Route, useLocation } from "react-router-dom";
import Recommendations from "./recommendations/Recommendations";
import YourAlerts from "./yourAlerts/YourAlerts";
import SearchResult from "./searchResult/SearchResult";
import "../../styles/pages/employment/Employment.css";
import "../../styles/pages/employment/FiltersEmployment.css";
import Header from "../../components/pages/principalNav/PrincipalNav";

const { Option } = Select;

const EmpleoRecomendaciones: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [clickedInput, setClickedInput] = useState<string | null>(null);

  const handleInputClick = (name: string) => {
    setClickedInput(prev => (prev === name ? null : name));
  };

  return (
    <>
      <div>
        <Header />
      </div>

      {/* Barra de búsqueda */}
      <div className="search-bar-merere">
        <Row gutter={5} align="middle" style={{ width: '100%' }}>
          <Col xl={24} md={24}>
            <div style={{ display: 'flex' }}>
              <div>
                <Input
                  prefix={<img src={IconSearch} width={24} height={24} />}
                  placeholder="Buscar empleo de..."
                  className={`Input-Filter-Employment w-[418px] mr-[8px] ${
                    clickedInput === "search" ? "clicked" : ""
                  }`}
                  onClick={() => handleInputClick("search")}
                />
              </div>
              <div>
                <Select
                  placeholder="Sector (Opcional)"
                  className={`Select-Filter-Employment mr-[8px] w-[225px] ${
                    clickedInput === "select" ? "clicked" : ""
                  }`}
                  onClick={() => handleInputClick("select")}
                >
                  <Option value="sector1">Sector 1</Option>
                  <Option value="sector2">Sector 2</Option>
                  <Option value="sector3">Sector 3</Option>
                </Select>
              </div>
              <div>
                <Input
                  prefix={<img src={IconLocation} className='mr-[3px]' />}
                  placeholder="Ciudad, provincia..."
                  className={`Input-Filter-Employment mr-[8px] w-[245px] ${
                    clickedInput === "location" ? "clicked" : ""
                  }`}
                  onClick={() => handleInputClick("location")}
                />
              </div>
              <div>
                <div
                  className={`Button-Filter-Employment h-[44px] ${
                    clickedInput === "button" ? "clicked" : ""
                  }`}
                  onClick={() => handleInputClick("button")}
                >
                  Buscar
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>

      {/* Tabs de navegación */}
      <div className="tabs-merere mt-4">
        <div className="tab-links-merere flex justify-center px-4">
          <button
            className={`tab-link-merere ${
              location.pathname === "/employment/recommendations"
                ? "active-merere"
                : ""
            }`}
            onClick={() => navigate("/employment/recommendations")}
          >
            <img src={IconRecommendations} className="icon-merere" />{" "}
            <span className="inline">Recomendaciones</span>
          </button>
          <button
            className={`tab-link-merere ${
              location.pathname === "/employment/your-alerts"
                ? "active-merere"
                : ""
            }`}
            onClick={() => navigate("/employment/your-alerts")}
          >
            <img src={IconAlerts} className="icon-merere" />{" "}
            <span className="inline">Tus alertas</span>
          </button>
          <button
            className={`tab-link-merere ${
              location.pathname === "/employment/search-result"
                ? "active-merere"
                : ""
            }`}
            onClick={() => navigate("/employment/search-result")}
          >
            <img src={IconResults} className="icon-merere" />{" "}
            <span className="inline">Resultado de tu búsqueda</span>
          </button>
        </div>

        {/* Contenido de las tabs */}
        <div className="tab-content-merere p-4">
          <Routes>
            <Route path="recommendations" element={<Recommendations />} />
            <Route path="your-alerts" element={<YourAlerts />} />
            <Route path="search-result" element={<SearchResult />} />
          </Routes>
        </div>
      </div>
    </>
  );
};

export default EmpleoRecomendaciones;
