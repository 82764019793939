import React, { useState } from "react";
import { Typography, Row, Col } from "antd";
import "tailwindcss/tailwind.css";
import IconEdit from '../../../assets/icons/EditP.svg';
import IconMas from '../../../assets/icons/plusP.svg';
import edit from '../../../assets/icons/edit.svg';
import ModalEditPortfolio from "./Modals/ModalEditPortfolio"; // Asegúrate de importar el modal

const { Title, Text } = Typography;

interface Project {
  name: string;
  description: string;
  skills: string;
  link: string;
  logoUrl: string;
}

interface PortfolioProps {
  projects: Project[];
}

const Portfolio: React.FC<PortfolioProps> = ({ projects }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);

  const handleEditClick = () => {
    setIsModalVisible(true);
  };

  const handleAddClick = () => {
    console.log("Add button clicked");
    // Aquí puedes añadir la lógica para abrir un modal o redirigir a la página de agregar
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="pb-8">
      <h1 className="text-heading-md font-bold pb-3 flex items-center">
        Portafolio
        <img
          src={IconEdit}
          alt="Editar"
          onClick={handleEditClick} // Este IconEdit abre el modal
          className="inline-block text-sky-blue0 pl-3 cursor-pointer w-[45px]"
        />
        <img
          src={IconMas}
          alt="Añadir"
          onClick={handleAddClick}
          className="inline-block text-sky-blue0 pl-3 cursor-pointer w-[45px]"
        />
      </h1>
      {projects.map((project, index) => (
        <Row key={index} className="mb-8">
          <Col xs={24} sm={24} lg={16} className="mb-8">
            <h2 className="text-heading-x1 font-bold mb-4 flex items-center">
              {project.name}
              <img
                src={edit}
                alt="Editar"
                onClick={() => console.log("Edit project clicked")}
                className="inline-block text-sky-blue0 pl-2 cursor-pointer"
              />
            </h2>
            <Row className="w-full">
              <Col
                xs={24} sm={9} lg={8}
                className="flex justify-center items-center mb-4 sm:mb-0"
              >
                <div className="mr-4">
                  <img
                    src={project.logoUrl}
                    className="w-205 border-blue0 object-contain"
                  />
                </div>
              </Col>
              <Col xs={24} sm={16} lg={16}>
                <div>
                  <p className="text-body-md font-bold mb-1">
                    {project.description}
                  </p>
                  <p className="text-body-md font-medium">{project.skills}</p>
                  <a
                    href={project.link}
                    className="text-body-md font-medium text-blue-600 hover:underline"
                  >
                    {project.link}
                  </a>
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} lg={8} className="flex pl-4 items-center">
            <div className=" ">
              <h2 className="text-heading-x1 font-bold flex items-center mb-2">Título del proyecto
                <img
                  src={edit}
                  alt="Editar"
                  onClick={() => console.log("Edit project clicked")}
                  className="inline-block text-sky-blue0 pl-2 cursor-pointer "
                />
              </h2>
              <ul className="list-none space-y-1">
                <li>
                  <strong>Descripción:</strong> {project.description}
                </li>
                <li>
                  <strong>Habilidades:</strong> {project.skills}
                </li>
                <li>
                  <strong>Sitio web:</strong>{" "}
                  <a
                    href={project.link}
                    className="text-blue-600 hover:underline"
                  >
                    {project.link}
                  </a>
                </li>
                <li>
                  <strong>Imágenes | vídeos:</strong> Ejemplo
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      ))}

      {/* Modal de edición */}
      <ModalEditPortfolio
        visible={isModalVisible}
        onClose={handleModalClose}
        skills={[]}
      // project={selectedProject}
      />
    </div>
  );
};

export default Portfolio;
