import React, { useState, useEffect } from "react";
import { Modal, Button, Input } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import IconClosed from "../../../../assets/icons/IconClosed.svg";
import InputC from "../../../../components/pages/offers/KillerQuestions/ComponentsKillersQ/Input";
import ModalSaved from '../../myPortal/Modals/ModalSavedChanges';
import ModalDeleteEducation from "./ModalDeleteEducation";
import ModalAddEducation from "./ModalAddEducation";
import SwitchOptions from '../../../../components/pages/myPortal/SwitchOptions';

interface ModalEditEducationProps {
  visible: boolean;
  onClose: () => void;
}

const ModalEditEducation: React.FC<ModalEditEducationProps> = ({ visible, onClose }) => {
  // Estados para cada campo de entrada
  const [companyName, setCompanyName] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [sector, setSector] = useState("");
  const [workLocation, setWorkLocation] = useState("");
  const [isCurrentlyWorking, setIsCurrentlyWorking] = useState(false);
  const [comment, setComment] = useState("");
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  // Estados para la visibilidad de los modales
  const [isSecondModalVisible, setIsSecondModalVisible] = useState(false);
  const [isDeleteSkillModalVisible, setIsDeleteSkillModalVisible] = useState(false);
  const [isAddWorkModalVisible, setIsAddWorkModalVisible] = useState(false); // Nuevo estado para el modal de agregar experiencia laboral

  // Manejo de cambios en los inputs
  const handleCompanyNameChange = (e: React.ChangeEvent<HTMLInputElement>) => setCompanyName(e.target.value);
  const handleJobTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => setJobTitle(e.target.value);
  const handleSectorChange = (e: React.ChangeEvent<HTMLInputElement>) => setSector(e.target.value);
  const handleWorkLocationChange = (e: React.ChangeEvent<HTMLInputElement>) => setWorkLocation(e.target.value);
  const handleCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => setComment(e.target.value);

  // Habilitar el botón Guardar cuando todos los campos obligatorios estén llenos
  useEffect(() => {
    const isValid = companyName.length > 0 && jobTitle.length > 0 && sector.length > 0 && workLocation.length > 0;
    setIsSubmitDisabled(!isValid);
  }, [companyName, jobTitle, sector, workLocation]);

  const handleSaveChanges = () => {
    setIsSecondModalVisible(true); // Muestra el modal de confirmación
  };

  const handleDeleteSkill = () => {
    setIsDeleteSkillModalVisible(true); // Muestra el modal de eliminación
  };

  const handleAddWork = () => {
    setIsAddWorkModalVisible(true); // Muestra el modal de añadir experiencia laboral
  };

  return (
    <>
      <Modal
        open={visible}
        onCancel={onClose}
        footer={null}
        centered
        closable={false}
        width={677}
        bodyStyle={{ borderRadius: "12px" }}
        style={{ borderRadius: "12px", border: "1px solid #E1E1E2", marginTop: '15px', marginBottom: '15px' }}
      >
        <img
          src={IconClosed}
          alt="Cerrar"
          onClick={onClose}
          style={{
            position: "absolute",
            top: "34px",
            right: "34px",
            cursor: "pointer",
            width: "24px",
            height: "24px",
          }}
        />

        <div className="text-center mx-[86px] mb-[36px] mt-[21px]">
          <h3 className="mt-[16px] mx-[44px] text-heading-md font-bold">
            Editar Educación
          </h3>
          <p className="font-medium px-[55px] text-body-sm mt-[10px]">
            Por favor, a continuación edita tu información
          </p>
        </div>

        <div className="mx-[68px] mb-[32px]  mt-[43px]">
          <span className="text-body-md font-normal text-[#5F5F5F]">Nombre del centro</span>
          <InputC
            placeholder="Nombre del centro"
            value={companyName}
            onChange={handleCompanyNameChange}
            style={{
              marginBottom: "23px",
              borderRadius: "12px",
              height: "36px",
              marginTop: "10px",
            }}
          />

          <span className="text-body-md font-normal text-[#5F5F5F]">Lugar del centro</span>
          <InputC
            placeholder="Lugar del centro"
            value={jobTitle}
            onChange={handleJobTitleChange}
            style={{
              marginBottom: "20px",
              borderRadius: "12px",
              height: "36px",
              marginTop: "10px",
            }}
          />

          <span className="text-body-md font-normal text-[#5F5F5F]">Titulo</span>
          <InputC
            placeholder="Titulo"
            value={sector}
            onChange={handleSectorChange}
            style={{
              marginBottom: "20px",
              borderRadius: "12px",
              height: "36px",
              marginTop: "10px",
            }}
          />

          <span className="text-body-md font-normal text-[#5F5F5F]">Especialidad</span>
          <InputC
            placeholder="Especialidad"
            value={workLocation}
            onChange={handleWorkLocationChange}
            style={{
              marginBottom: "20px",
              borderRadius: "12px",
              height: "36px",
              marginTop: "10px",
            }}
          />

          <div>
            <SwitchOptions
              visible={true}
              onClose={() => console.log("Modal cerrado")}
              defaultWorking={false} />
          </div>

          <div className=" mb-[32px] mt-[40px]">
            <span className="text-body-md text-[#757575] font-normal">Descripción del trabajo</span>
            <Input.TextArea
              rows={5}
              value={comment}
              onChange={handleCommentChange}
              className="w-full 
                border
                my-[8px]
                border-[#D9D9D9] 
                placeholder:text-green32 
                focus:placeholder:text-grays 
                hover:placeholder:text-black 
                hover:bg-gray3 
                hover:border-2 
                hover:border-[#D9D9D9]
                hover:text-black 
                focus:border-4 
                focus:border-[#91c3fd] 
                focus:text-[#757575]
                rounded-[8px] 
                transition-all 
                duration-200 
                text-[#757575]
                font-normal
                text-body-md"
              style={{ height: 80 }}
            />
            <span className="text-body-md font-normal text-[#757575]">
              Máximo 400 caracteres
            </span>

            <div className="flex mx-[86px] justify-center mt-[40px]">
              <Button
                className="text-blue3 font-semibold border border-white w-[70px] h-[36px] principal-nav-notify-buttonS flex items-center justify-center"
                onClick={handleDeleteSkill}
              >
                Eliminar
              </Button>
              <Button
                className="text-blue3 font-semibold border border-white w-[70px] h-[36px] principal-nav-notify-buttonS flex items-center justify-center"
                onClick={handleSaveChanges}
              >
                Guardar
              </Button>
            </div>
          </div>
        </div>
        <div className="mx-[68px] mb-[32px]  mt-[43px]">
          <span className="text-body-md font-normal text-[#5F5F5F]">Nombre del centro</span>
          <InputC
            placeholder="Nombre del centro"
            value={companyName}
            onChange={handleCompanyNameChange}
            style={{
              marginBottom: "23px",
              borderRadius: "12px",
              height: "36px",
              marginTop: "10px",
            }}
          />

          <span className="text-body-md font-normal text-[#5F5F5F]">Lugar del centro</span>
          <InputC
            placeholder="Lugar del centro"
            value={jobTitle}
            onChange={handleJobTitleChange}
            style={{
              marginBottom: "20px",
              borderRadius: "12px",
              height: "36px",
              marginTop: "10px",
            }}
          />

          <span className="text-body-md font-normal text-[#5F5F5F]">Titulo</span>
          <InputC
            placeholder="Titulo"
            value={sector}
            onChange={handleSectorChange}
            style={{
              marginBottom: "20px",
              borderRadius: "12px",
              height: "36px",
              marginTop: "10px",
            }}
          />

          <span className="text-body-md font-normal text-[#5F5F5F]">Especialidad</span>
          <InputC
            placeholder="Especialidad"
            value={workLocation}
            onChange={handleWorkLocationChange}
            style={{
              marginBottom: "20px",
              borderRadius: "12px",
              height: "36px",
              marginTop: "10px",
            }}
          />
          <SwitchOptions
            visible={true}
            onClose={() => console.log("Modal cerrado")}
            defaultWorking={true} />

          <div className=" mb-[32px] mt-[40px]">
            <span className="text-body-md text-[#757575] font-normal">Descripción del trabajo</span>
            <Input.TextArea
              rows={5}
              value={comment}
              onChange={handleCommentChange}
              className="w-full 
                border
                my-[8px]
                border-[#D9D9D9] 
                placeholder:text-green32 
                focus:placeholder:text-grays 
                hover:placeholder:text-black 
                hover:bg-gray3 
                hover:border-2 
                hover:border-[#D9D9D9]
                hover:text-black 
                focus:border-4 
                focus:border-[#91c3fd] 
                focus:text-[#757575]
                rounded-[8px] 
                transition-all 
                duration-200 
                text-[#757575]
                font-normal
                text-body-md"
              style={{ height: 80 }}
            />
            <span className="text-body-md font-normal text-[#757575]">
              Máximo 400 caracteres
            </span>

            <div className="flex mx-[86px] justify-center mt-[40px]">
              <Button
                className="text-blue3 font-semibold border border-white w-[70px] h-[36px] principal-nav-notify-buttonS flex items-center justify-center"
                onClick={handleDeleteSkill}
              >
                Eliminar
              </Button>
              <Button
                className="text-blue3 font-semibold border border-white w-[70px] h-[36px] principal-nav-notify-buttonS flex items-center justify-center"
                onClick={handleSaveChanges}
              >
                Guardar
              </Button>
            </div>
            <div className="flex justify-center mt-[17px]">
              <Button
                className="text-blue3 font-semibold border border-white w-[296px] h-[36px] principal-nav-notify-buttonS flex items-center justify-center"
                onClick={handleAddWork}
              >
                <PlusOutlined />
                Añadir experiencia laboral
              </Button>
            </div>
          </div>
        </div>
      </Modal>

      {/* Modal para confirmación de guardado */}
      <ModalSaved
        visible={isSecondModalVisible}
        onClose={() => setIsSecondModalVisible(false)}
      />

      {/* Modal para confirmación de eliminación */}
      <ModalDeleteEducation
        visible={isDeleteSkillModalVisible}
        onClose={() => setIsDeleteSkillModalVisible(false)}
        skillName={null}
      />

      {/* Modal para añadir experiencia laboral */}
      <ModalAddEducation
        visible={isAddWorkModalVisible}
        onClose={() => setIsAddWorkModalVisible(false)}
      />
    </>
  );
};

export default ModalEditEducation;
