import React, { useState } from "react";
import { Row, Col, Drawer, Button } from "antd";
import { Routes, Route } from "react-router-dom";
import Header from "../../components/pages/principalNav/PrincipalNav";
import SidebarProfile from "../../components/sidebarProfile/SidebarProfile";
import HelpExample from "../../pages/myPortal/help/Help";
import SettingExample from "../../pages/myPortal/generalSettings/GeneralSettings";
import ProfileExample from "../../pages/myPortal/profile/Profile";
import profilePicture from "../../assets/img/MyPortal/ImageP.svg";
import headerImage from "../../assets/img/MyPortal/imageS.svg";
import { MenuOutlined } from "@ant-design/icons";

const MyPortal: React.FC = () => {
  const [isJobSearchActive, setIsJobSearchActive] = useState(true);
  const [drawerVisible, setDrawerVisible] = useState(false);

  const handleJobSearchToggle = (checked: boolean) => {
    setIsJobSearchActive(checked);
  };

  const toggleDrawer = () => {
    setDrawerVisible(!drawerVisible);
  };

  const sidebarData = {
    profilePicture: profilePicture,
    name: "Elena Rodriguez",
    jobTitle: "Vendedora",
    location: "Paterna, Valencia",
  };

  return (
    <>
      <Header />
      <div className="relative w-full px-7">
        <img
          src={headerImage}
          alt="Header"
          className="w-full h-64 object-cover rounded-lg"
        />
      </div>

      <div className="bg-white p-6">
        <div className="flex">

          <div className="hidden md:block" style={{ width: "234px" }}>
            <SidebarProfile
              profilePicture={sidebarData.profilePicture}
              name={sidebarData.name}
              jobTitle={sidebarData.jobTitle}
              location={sidebarData.location}
              isJobSearchActive={isJobSearchActive}
              onJobSearchToggle={handleJobSearchToggle}
              width={234}
            />
          </div>

          <Drawer
            title={null}
            placement="left"
            onClose={toggleDrawer}
            visible={drawerVisible}
            width={300}
            className="md:hidden"
          >
            <SidebarProfile
              profilePicture={sidebarData.profilePicture}
              name={sidebarData.name}
              jobTitle={sidebarData.jobTitle}
              location={sidebarData.location}
              isJobSearchActive={isJobSearchActive}
              onJobSearchToggle={handleJobSearchToggle}
              width={234}
            />
          </Drawer>

          <div className="flex-1 pl-6 pr-1">
            <Row gutter={[16, 16]}>
              <Col xs={24} md={24}>
              <Button
            className="md:hidden mb-4"
            type="primary"
            icon={<MenuOutlined />}
            onClick={toggleDrawer}
          />
                <Routes>
                  <Route path="profile" element={<ProfileExample />} />
                  <Route path="help" element={<HelpExample />} />
                  <Route path="generalSettings" element={<SettingExample />} />
                </Routes>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyPortal;
