import React, { useEffect, useState } from "react";
import { EyeFilled } from "@ant-design/icons";
import { Row, Col, Button, App, Divider, Segmented } from "antd";
import { useNavigate } from "react-router-dom";
import { EnvironmentOutlined } from "@ant-design/icons";
import Header from "../../../components/pages/principalNav/HeaderOffers";
import "../../../styles/pages/offers/estilos.css";
import InputC from "../../../components/pages/offers/CreateOffers/Input";
import Select from "../../../components/pages/offers/CreateOffers/Select";
import Sliders from "../../../components/pages/offers/CreateOffers/Slider";
import CustomButton from "../../../components/pages/offers/CreateOffers/Buttons";
import CustomTag from "../../../components/pages/offers/CreateOffers/CustomTag";
import "../../../components/styles/pages/principalNav/PrincipalNav.css";
import CheckboxC from "../../../components/checkbox/CheckboxProps";
import Ckeditor from "../../../components/pages/offers/CreateOffers/Ckeditor";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../redux/store/store";
import {
  CreateOfferReducer,
  ResetCreateOfferReducer,
} from "../../../redux/actions/offers/CreateOffers";
import { GetSkillsReducer } from "../../../redux/actions/offers/skills/Skills";

interface offerCreate {
  empresa_id: number;
  sector_id: number;
  cargo: string;
  descripcion: string;
  tipo: string;
  ubi_provincia: string;
  ubi_poblacion: string;
  sal_min: number;
  sal_max: number;
  abanico_salarial: string;
  anios_experiencia: number;
  estudios_minimos: string;
  tipo_contrato: string;
  jornada_laboral: string;
}

const initialVAlues: offerCreate = {
  empresa_id: 1,
  sector_id: 1,
  cargo: "",
  tipo: "inserte tipo",
  anios_experiencia: 1,
  descripcion: "",
  ubi_poblacion: "",
  ubi_provincia: "",
  sal_max: 0,
  sal_min: 0,
  abanico_salarial: "Año",
  estudios_minimos: "",
  tipo_contrato: "",
  jornada_laboral: "",

  // synonyms: '',
  // province: '',
  // population: '',
  // remote: false,
  // minSalary: '',
  // maxSalary: '',
  // salaryFrequency: 'Año',
  // jobDescription: '',
  // yearsExperience: '',
  // minStudies: '',
  // contractType: '',
  // workingHours: '',
  // sector: '',
  // skills: '',
  // keywords: '',
};

const CreateOffer: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { notification } = App.useApp();
  const { rex_loading, rex_request } = useSelector(
    ({ createOffers }: any) => createOffers
  );

  // Estado para manejar las aptitudes y las seleccionadas
  const [aptitudes, setAptitudes] = useState<any[]>([]);
  const [selectedAptitudes, setSelectedAptitudes] = useState<string[]>([]);

  // Obtener aptitudes desde la API
  useEffect(() => {
    const fetchAptitudes = async () => {
      const response = await dispatch(GetSkillsReducer()); // Ya no necesitas los parámetros
      console.log("Respuesta completa de la API: ", response);

      // Accedemos a response.payload.data ya que la API devuelve los datos en esa propiedad
      if (response?.payload?.data) {
        const aptitudesData = response.payload.data.map((aptitud: any) => ({
          value: aptitud.id,
          label: aptitud.aptitud,
        }));
        console.log("Datos procesados para el select: ", aptitudesData);
        setAptitudes(aptitudesData);
      } else {
        console.error("No se encontraron datos en la API.");
      }
    };

    fetchAptitudes();
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();

  const handleTagClose = (aptitud: string) => {
    // Elimina la aptitud seleccionada del array
    setSelectedAptitudes((prev) => prev.filter((item) => item !== aptitud));
  };

  const handleAptitudeSelect = (value: any) => {
    const selectedLabel = aptitudes.find((apt) => apt.value === value)?.label;

    // Verificamos si la aptitud seleccionada ya existe en el array de selectedAptitudes
    if (selectedLabel && !selectedAptitudes.includes(selectedLabel)) {
      setSelectedAptitudes([...selectedAptitudes, selectedLabel]);
    }
  };

  type Align = "start" | "center" | "end";
  const [alignValue, setAlignValue] = React.useState<Align>("center");

  const handleClose = (text: string) => {
    console.log(`Closed tag: ${text}`);
  };

  useEffect(() => {
    if (rex_request) {
      if (rex_request.respuesta) {
        dispatch(ResetCreateOfferReducer());
        notification.success({ message: rex_request.mensaje });
        navigate(-1);
      } else {
        // notification.error({ message: rex_request.mensaje });
      }
    }
  }, [rex_request]);

  return (
    <>
      <Header />
      <Formik
        initialValues={initialVAlues}
        onSubmit={async (values, { resetForm }) => {
          console.log(values);
          // navigate("/offers/killerQuestions");
          await dispatch(CreateOfferReducer(values));
          resetForm();
          window.scrollTo(0, 0);
        }}
      >
        {({ handleChange, handleBlur, values, setFieldValue }) => (
          <Form>
            <div className="bg-white px-[24px] pt-[8px]">
              <h1 className="font-bold text-heading-md pt-[16px]">
                Creación de nueva oferta de empleo
                <Divider className=" bg-grays"></Divider>
              </h1>

              <Row gutter={24} className="mb-[40px]">
                <Col span={9} className="mr-[0px]">
                  <div className="w-[478px]">
                    <h3 className="font-medium text-heading-x1 mb-[8px]">
                      Puesto de trabajo *
                    </h3>
                    <Field
                      name="cargo"
                      as={InputC}
                      placeholder="Escribe las primeras letras del cargo..."
                      customClassName="h-[44px]"
                      className="mb-1"
                    />
                    <ErrorMessage
                      name="cargo"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>
                </Col>
                <Col span={8}>
                  <div className="">
                    <h3 className="font-medium text-heading-x1 mb-[8px] ">
                      Elige entre estos posibles sinónimos
                    </h3>
                    <div className="flex space-x-2">
                      <CustomButton text="Cajero" />
                      <CustomButton text="Cajero de supermercado" />
                      <CustomButton text="Reponedor" />
                    </div>
                  </div>
                </Col>
              </Row>

              <Row gutter={24} className="mb-[40px]">
                <Col span={9} className="">
                  <div>
                    <h3 className="font-medium text-heading-x1 mb-[8px]">
                      Lugar del puesto de trabajo
                    </h3>
                    <Row gutter={24} className="">
                      <Col span={12}>
                        <Field
                          name="ubi_provincia"
                          as={InputC}
                          placeholder="Provincia... *"
                          customClassName="h-[44px]"
                          className="mb-1"
                          prefix={<EnvironmentOutlined />}
                        />
                        <ErrorMessage
                          name="ubi_provincia"
                          component="div"
                          className="text-red-500 text-sm"
                        />
                      </Col>
                      <Col span={12}>
                        <Field
                          name="ubi_poblacion"
                          as={InputC}
                          placeholder="Población... *"
                          customClassName="h-[44px]"
                          className="mb-1"
                          prefix={<EnvironmentOutlined />}
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>

                <Col span={6}>
                  <div className="flex items-center pt-[35px] ml-[11px]">
                    <CheckboxC value={0} children={undefined} />
                    <h1 className="font-medium text-body-sm text-gray">
                      En remoto
                    </h1>
                    <Button className="bg-blue3 text-white w-[102px] ml-[18px] principal-nav-notify-button2 rounded-[4px] ">
                      Buscar
                    </Button>
                  </div>
                </Col>
              </Row>

              <Row gutter={24} className="mb-[10px]">
                <Col span={9}>
                  <div>
                    <h3 className="font-medium text-heading-x1 mb-[8px]">
                      Compromiso de transparecia salarial *
                    </h3>
                    <Row gutter={24}>
                      <Col span={12}>
                        <Field
                          name="sal_min"
                          as={InputC}
                          placeholder="Salario mínimo *"
                          customClassName="h-[44px]"
                          className="mb-1"
                          type="number"
                        />
                        <ErrorMessage
                          name="sal_min"
                          component="div"
                          className="text-red-500 text-sm"
                        />
                      </Col>
                      <Col span={12}>
                        <Field
                          name="sal_max"
                          as={InputC}
                          placeholder="Salario máximo *"
                          customClassName="h-[44px]"
                          className="mb-1"
                          type="number"
                        />
                        <ErrorMessage
                          name="sal_max"
                          component="div"
                          className="text-red-500 text-sm"
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>

                <Col span={9}>
                  <div className="pt-[42px]">
                    <Segmented
                      defaultValue="Año"
                      style={{ marginBottom: 30, fontWeight: "600" }}
                      onChange={(value) => {
                        setFieldValue("abanico_salarial", value);
                        setAlignValue(value as Align);
                      }}
                      options={["Año", "Mes", "Hora"]}
                    />
                    <span className="font-medium text-body-sm text-gray pl-[24px]">
                      Informa del abanico salarial *
                    </span>
                  </div>
                </Col>
              </Row>

              <Row gutter={24} className="mb-[40px]">
                <Col span={24} className="">
                  <h3 className="font-medium text-heading-x1 mb-[8px]">
                    Descripción del trabajo *
                  </h3>
                  <Ckeditor setFieldValue={setFieldValue} />
                  {/*} <Input.TextArea
              rows={5}
              placeholder="Ofrece información detallada y específica sobre los requerimientos del puesto"
              className="w-full 
                border
                border-blue3 
                placeholder:text-green32 
                focus:placeholder:text-grays 
                hover:placeholder:text-black 
                hover:bg-gray3 
                hover:border-2 
                hover:border-blue3 
                hover:text-black 
                focus:border-4 
                focus:border-[#91c3fd] 
                focus:text-green32 
                rounded-[4px] 
                transition-all 
                duration-200 
                text-black
                font-medium
                text-body-md"
              style={{ height: 156 }}
            />
          */}
                </Col>
              </Row>

              <Row gutter={24} className="my-4">
                <Col span={9}>
                  <div>
                    <h3 className="font-medium text-heading-x1 mb-[8px] mt-[40px]">
                      Información sobre el puesto
                    </h3>
                    <Row gutter={24} className="">
                      <Col span={12}>
                        <h3 className="font-medium text-body-md text-green32 mb-2">
                          Años de experiencia
                        </h3>
                        <Select
                          showSearch
                          placeholder="Seleccionar"
                          size="large"
                          filterOption={(input, option: any) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          fetchOptions={async (search) => {
                            return [
                              { value: "0-1", label: "0-1 años" },
                              { value: "2-3", label: "2-3 años" },
                              { value: "4-5", label: "4-5 años" },
                              { value: "5+", label: "Más de 5 años" },
                            ];
                          }}
                        />
                      </Col>
                      <Col span={12}>
                        <h3 className="font-medium text-body-md text-green32 mb-2">
                          Estudios mínimos *
                        </h3>
                        <Select
                          showSearch
                          placeholder="Seleccionar"
                          size="large"
                          fetchOptions={async (search) => {
                            return [
                              { value: "bachillerato", label: "Bachillerato" },
                              { value: "diplomado", label: "Diplomado" },
                              { value: "grado", label: "Grado" },
                              { value: "master", label: "Máster" },
                            ].filter((option) =>
                              option.label
                                .toLowerCase()
                                .includes(search.toLowerCase())
                            );
                          }}
                          onChange={(value) =>
                            setFieldValue("estudios_minimos", value)
                          }
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>

              <Row gutter={24}>
                <Col span={9}>
                  <div>
                    <Row gutter={24} className="">
                      <Col span={12}>
                        <h3 className="font-medium text-body-md text-green32 mb-2">
                          Tipo de contrato *
                        </h3>
                        <Select
                          showSearch
                          placeholder="Seleccionar"
                          size="large"
                          fetchOptions={async (search) => {
                            return [
                              { value: "indefinido", label: "Indefinido" },
                              { value: "temporal", label: "Temporal" },
                              { value: "freelance", label: "Freelance" },
                            ].filter((option) =>
                              option.label
                                .toLowerCase()
                                .includes(search.toLowerCase())
                            );
                          }}
                          onChange={(value) =>
                            setFieldValue("tipo_contrato", value)
                          }
                        />
                      </Col>
                      <Col span={12}>
                        <h3 className="font-medium text-body-md text-green32 mb-2">
                          Jornada laboral *
                        </h3>
                        <Select
                          showSearch
                          placeholder="Seleccionar"
                          size="large"
                          fetchOptions={async (search) => {
                            return [
                              { value: "completa", label: "Completa" },
                              { value: "media", label: "Media Jornada" },
                              { value: "flexible", label: "Flexible" },
                            ].filter((option) =>
                              option.label
                                .toLowerCase()
                                .includes(search.toLowerCase())
                            );
                          }}
                          onChange={(value) =>
                            setFieldValue("jornada_laboral", value)
                          }
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>
                <div className="divider"></div>

                <Col span={12}>
                  <div>
                    <Row gutter={24}>
                      <Col span={9}>
                        <h3 className="font-medium text-body-md text-green32 mb-2">
                          Sector
                        </h3>
                        <Select
                          showSearch
                          placeholder="Seleccionar"
                          size="large"
                          fetchOptions={async (search) => {
                            return [
                              { value: "retail", label: "Retail" },
                              { value: "supermercado", label: "Supermercado" },
                              { value: "tecnologia", label: "Tecnología" },
                              { value: "salud", label: "Salud" },
                            ].filter((option) =>
                              option.label
                                .toLowerCase()
                                .includes(search.toLowerCase())
                            );
                          }}
                        />
                      </Col>
                      <Col span={11} className="pt-[40px]">
                        <span className="font-medium text-body-sm text-gray">
                          Retail x Reponedor de supermercado x
                        </span>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>

              <Divider className="bg-blue3 my-[40px]"></Divider>

              <Row>
                <Col>
                  <h3 className="font-bold text-heading-x1 mb-[40px]">
                    ¿Cómo sería tu candidato ideal?
                  </h3>
                </Col>
                <Col>
                  <h3 className="ml-2 text-heading-x1">
                    Esta información no saldra en la oferta
                  </h3>
                </Col>
              </Row>

              <Row gutter={24} className="my-4">
                <Col span={15}>
                  <h3 className="font-medium text-heading-x1 mb-[8px]">
                    Aptitudes / Tecnología
                  </h3>
                  <Row gutter={24} className="mb-[20px]">
                    <Col span={15}>
                      <div className="w-[225px]">
                      <Select
  showSearch
  placeholder="Seleccionar"
  size="large"
  fetchOptions={async (search) => {
    // Realiza la búsqueda con el filtro en todas las páginas
    const response = await dispatch(GetSkillsReducer(search, 1, 50)); // Buscar en las primeras 50 coincidencias
    return response.payload.data.map((aptitud: any) => ({
      label: aptitud.aptitud,
      value: aptitud.id,
    }));
  }}
  onChange={(newValue) => {
    const selectedItems = Array.isArray(newValue) ? newValue : [newValue];
    const selectedLabels = selectedItems.map((item: any) => item.label || item.value);
    setSelectedAptitudes(selectedLabels);
  }}
  style={{ width: "100%" }}
/>

                      </div>
                    </Col>

                    <Col span={24}>
                      <div className="flex flex-wrap mt-2">
                        {selectedAptitudes.map((aptitud) => (
                          <CustomTag
                            key={aptitud}
                            text={aptitud} // Muestra el texto de la aptitud seleccionada
                            onClose={() => handleTagClose(aptitud)} // Elimina la aptitud cuando se cierra el tag
                          />
                        ))}
                      </div>
                    </Col>
                  </Row>

                  <h3 className="font-medium text-heading-x1 mb-[8px]">
                    Palabras clave
                  </h3>
                  <Row gutter={24}>
                    <Col span={15}>
                      <div className="w-[225px]">
                        <InputC placeholder="Pulsa intro para siguiente" />
                      </div>
                    </Col>
                    <Col span={24}>
                      <div className="flex flex-wrap mt-2">
                        <CustomTag
                          text="Campo de Criptana"
                          onClose={() => handleClose("Campo de Criptana")}
                        />
                        <CustomTag
                          text="Alcanzar de San juan"
                          onClose={() => handleClose("Alcanzar de San juan")}
                        />
                        <CustomTag
                          text="Herencia"
                          onClose={() => handleClose("Herencia")}
                        />
                        <CustomTag
                          text="Bachillerato"
                          onClose={() => handleClose("Bachillerato")}
                        />
                        <CustomTag
                          text="Más de 2 años de experiencia"
                          onClose={() =>
                            handleClose("Más de 2 años de experiencia")
                          }
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>

                <Col span={9} className="mb-[144px]">
                  <h3 className="font-medium text-heading-x1 mb-2">
                    Soft skills
                  </h3>
                  <div className=" w-[476px] h-[362px]">
                    <Sliders
                      minLabel="Colaborativo"
                      maxLabel="Autónomo"
                      defaultValue={4}
                    />
                    <Sliders
                      minLabel="Innovador"
                      maxLabel="Metódico"
                      defaultValue={8}
                    />
                    <Sliders
                      minLabel="Detallista"
                      maxLabel="Visionario"
                      defaultValue={2}
                    />
                    <Sliders
                      minLabel="Resiliente"
                      maxLabel="Proactivo"
                      defaultValue={10}
                    />
                    <Sliders
                      minLabel="Adaptable"
                      maxLabel="Consistente"
                      defaultValue={6}
                    />
                  </div>
                </Col>
              </Row>

              <Row className="justify-end mb-[24px]">
                <Col>
                  <Button className="bg-white text-[#5F5F5F] border border-white w-[310px] mr-[8px] principal-nav-notify-button0 ">
                    Cancelar creación de oferta de empleo
                  </Button>
                </Col>
                <Col>
                  <Button className="text-blue3 w-[172px] border principal-nav-notify-button mr-[8px] text-body-md ">
                    <EyeFilled className="text-[24px]" /> Vista previa
                  </Button>
                </Col>
                <Col>
                  <Button
                    className="bg-blue3 text-white w-[122px] principal-nav-notify-button2"
                    onClick={() => navigate("/offers/killerQuestions")}
                  >
                    Siguiente
                  </Button>
                </Col>
                <Col>
                  <Button
                    className="bg-blue3 text-white w-[122px] principal-nav-notify-button2 pl-1 pr-1"
                    onClick={() => {
                      // navigate("/offers/killerQuestions")
                    }}
                    htmlType="submit"
                    loading={rex_loading}
                    iconPosition={"end"}
                  >
                    Crear Oferta
                  </Button>
                </Col>
              </Row>

              <br></br>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default CreateOffer;
