import React, { useState, useEffect } from "react";
import { Modal, Button } from "antd";
import IconClosed from "../../../../assets/icons/IconClosed.svg";
import ModalSaved from "./ModalSavedChanges";
import { PlusOutlined } from "@ant-design/icons";
import Select from "../Select";

interface ModalAddSkillsProps {
  visible: boolean;
  onClose: () => void;
  onAdd: (newSkill: string) => void;
}

const ModalAddSkills: React.FC<ModalAddSkillsProps> = ({ visible, onClose, onAdd }) => {
  const [selectedLanguage, setSelectedLanguage] = useState<string | null>(null);
  const [selectedLevel, setSelectedLevel] = useState<string | null>(null);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [isModalSavedVisible, setIsModalSavedVisible] = useState(false);

  useEffect(() => {
    setIsSubmitDisabled(!(selectedLanguage && selectedLevel));
  }, [selectedLanguage, selectedLevel]);

  const handleSaveSkill = () => {
    if (!isSubmitDisabled) {
      onAdd(`${selectedLanguage} - ${selectedLevel}`);
      setIsModalSavedVisible(true);
    }
  };

  const handleModalSavedClose = () => {
    setIsModalSavedVisible(false);
    onClose();
  };

  // Opciones para los selects
  const languageOptions = [
    { value: "English", label: "Inglés" },
    { value: "Spanish", label: "Español" },
    { value: "French", label: "Francés" },
    { value: "German", label: "Alemán" },
  ];

  const levelOptions = [
    { value: "Beginner", label: "B1" },
    { value: "Intermediate", label: "B2" },
    { value: "Advanced", label: "A1" },
    { value: "Fluent", label: "A2" },
  ];

  return (
    <>
      <Modal
        open={visible}
        onCancel={onClose}
        footer={null}
        centered
        closable={false}
        width={677}
        bodyStyle={{ borderRadius: "12px" }}
        style={{ borderRadius: "12px", border: "1px solid #E1E1E2", marginTop: "15px", marginBottom: "15px" }}
      >
        <img
          src={IconClosed}
          alt="Cerrar"
          onClick={onClose}
          style={{
            position: "absolute",
            top: "34px",
            right: "34px",
            cursor: "pointer",
            width: "24px",
            height: "24px",
          }}
        />

        <div className="text-center mx-[86px] mb-[36px] mt-[20px]">
          <h3 className="mt-[16px] mx-[44px] text-heading-md font-bold">
            Añadir idioma
          </h3>
          <p className="font-medium px-[55px] text-body-sm mt-[10px]">
          Por favor, a continuación edita tu información
          </p>
        </div>

        <div className="mx-[86px] mb-[32px] mt-[24px]">
          <div className="flex flex-col space-y-4 mx-[123px]">
            <Select
              placeholder="Idioma"
              className="w-full"
              value={selectedLanguage}
              onChange={value => setSelectedLanguage(value)}
              options={languageOptions}
            />
            <Select
              placeholder="Nivel"
              className="w-full"
              value={selectedLevel}
              onChange={value => setSelectedLevel(value)}
              options={levelOptions}
            />
          </div>
          <div className="flex justify-center mt-[36px]">
            <Button className="text-blue3 font-semibold border border-white w-[232px] h-[36px] principal-nav-notify-buttonS flex items-center justify-center">
              <PlusOutlined />
              Añadir idioma
            </Button>
          </div>

          <div className="flex justify-center mt-[40px]">
            <Button
              onClick={onClose}
              className="principal-nav-notify-buttonG w-[118px] h-[44px]"
              style={{ marginRight: "8px", borderRadius: "4px" }}
            >
              Cancelar
            </Button>
            <Button
              disabled={isSubmitDisabled}
              onClick={handleSaveSkill}
              className={`w-[181px] h-[44px] ml-[18px] rounded-[4px] ${isSubmitDisabled ? "bg-[#F4F4F5] text-[#757575] font-semibold text-body-md cursor-not-allowed" : "bg-blue3 principal-nav-notify-button2 text-white cursor-pointer"}`}
            >
              Guardar cambios
            </Button>
          </div>
        </div>
      </Modal>

      {/* ModalSaved */}
      <ModalSaved
        visible={isModalSavedVisible}
        onClose={handleModalSavedClose}
      />
    </>
  );
};

export default ModalAddSkills;
