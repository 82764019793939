import React, { useState } from "react";
import { Table, Tooltip } from "antd";
import { UpOutlined, DownOutlined } from "@ant-design/icons";
import "tailwindcss/tailwind.css";
import "./OpenEstilos.css";

interface Registrant {
  key: string;
  name: string;
  offer: string;
  ko: string;
}

const data: Registrant[] = [
  { key: "1", name: "Elena Rodríguez", offer: "Reponedor de supermercado", ko: "7/7 true" },
  { key: "2", name: "Lucía Romero", offer: "Mozo de almacén en la sección de productos perecederos", ko: "3/7 true" },
  { key: "3", name: "Carmen Delgado", offer: "Administrativo en la sección de recursos humanos", ko: "5/7 true" },
  { key: "4", name: "Lucía Romero", offer: "Mozo de almacén", ko: "4/7 true" },
  { key: "5", name: "Carmen Delgado", offer: "Administrativo", ko: "6/7 true" },
  { key: "6", name: "Carlos García", offer: "Cajero", ko: "6/7 true" },
  { key: "7", name: "Laura Pérez", offer: "Gerente", ko: "4/7 true" },
];

const LatestRegistrants: React.FC = () => {
  const [nameSortOrder, setNameSortOrder] = useState<"ascend" | "descend" | null>(null);
  const [offerSortOrder, setOfferSortOrder] = useState<"ascend" | "descend" | null>(null);
  const [koSortOrder, setKoSortOrder] = useState<"ascend" | "descend" | null>(null);

  const handleSort = (columnKey: string) => {
    switch (columnKey) {
      case "name":
        setNameSortOrder(nameSortOrder === "ascend" ? "descend" : "ascend");
        setOfferSortOrder(null);
        setKoSortOrder(null);
        break;
      case "offer":
        setOfferSortOrder(offerSortOrder === "ascend" ? "descend" : "ascend");
        setNameSortOrder(null);
        setKoSortOrder(null);
        break;
      case "ko":
        setKoSortOrder(koSortOrder === "ascend" ? "descend" : "ascend");
        setNameSortOrder(null);
        setOfferSortOrder(null);
        break;
    }
  };

  const columns = [
    {
      title: (
        <div onClick={() => handleSort("name")} className="flex items-center cursor-pointer  h-[6px]">
          <h1 className="font-bold text-caption">
            Nombre {nameSortOrder === "ascend" ? <UpOutlined className="ml-1" /> : <DownOutlined className="ml-1" />}
          </h1>
        </div>
      ),
      dataIndex: "name",
      key: "name",
      width: 182,
      render: (text: string) => (
        <h1 className="font-bold text-blue3 text-body-sm custom-cell">
          {text}
        </h1>
      ),
    },
    {
      title: (
        <div onClick={() => handleSort("offer")} className="flex items-center cursor-pointer  h-[6px]">
          <h1 className="font-bold text-caption">
            Oferta {offerSortOrder === "ascend" ? <UpOutlined className="ml-1" /> : <DownOutlined className="ml-1" />}
          </h1>
        </div>
      ),
      dataIndex: "offer",
      key: "offer",
      width: 107,
      render: (text: string) => (
        <Tooltip title={text}>
          <div className="text-body-sm font-bold text-[#5B5B5B] custom-cell">
            {text}
          </div>
        </Tooltip>
      ),
    },
    {
      title: (
        <div onClick={() => handleSort("ko")} className="flex items-center cursor-pointer  h-[6px]">
          <h1 className="font-bold text-caption">
            KO {koSortOrder === "ascend" ? <UpOutlined className="ml-1" /> : <DownOutlined className="ml-1" />}
          </h1>
        </div>
      ),
      dataIndex: "ko",
      key: "ko",
      width: 107,
      render: (text: string) => (
        <div className="text-body-sm font-bold text-[#5B5B5B] custom-cell">
          {text}
        </div>
      ),
    },
  ];

  return (
    <div className="px-[15px]">
      <h3 className="font-bold text-lg pb-[24px]">Últimos inscritos</h3>
      <div style={{ maxHeight: "200px", overflowY: "auto" }}>
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          bordered={false}
          rowClassName={(record, index) =>
            `custom-row ${index % 2 === 0 ? "bg-[#F2F2F2]" : "bg-[#E6E6E6]"}`
          }
          scroll={{ y: 200 }}
          className="custom-table"
        />
      </div>
    </div>
  );
};

export default LatestRegistrants;
