import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

// Datos del gráfico
const data = [
  { date: '13 Mayo, 2024', count: 200 },
  { date: '14 Mayo, 2024', count: 150 },
  { date: '15 Mayo, 2024', count: 180 },
  { date: '16 Mayo, 2024', count: 30 },
  { date: '17 Mayo, 2024', count: 30 },
  { date: '19 Mayo, 2024', count: 150 },
  { date: '20 Mayo, 2024', count: 220 },
];

const CandidatesEnrolledByDay: React.FC = () => {
  return (
    <div>
      <h3 className='font-bold text-heading-x1 pb-[25px]'>
        Candidatos inscrito por días
      </h3>
      <div className='ml-[-42px]'>
        <ResponsiveContainer width="100%" height={240}>
          <BarChart
            data={data}
            barCategoryGap={32} // Espacio entre las barras
            barSize={32} // Grosor de las barras
          >
            <CartesianGrid 
              stroke="#e0e0e0" 
              vertical={true} 
              horizontal={true}
              strokeDasharray="3 3" // Líneas horizontales guionadas
            />
            <XAxis
              dataKey="date"
              tick={{ fontSize: 12, fill: '#9e9e9e' }}
              axisLine={{ stroke: '#e0e0e0' }}
              tickLine={false}
              ticks={[data[0].date, data[data.length - 1].date]} // Mostrar solo los valores en los extremos
            />
            <YAxis
              tick={{ fontSize: 12, fill: '#9e9e9e' }}
              axisLine={{ stroke: '#e0e0e0' }}
              tickLine={false}
              domain={[0, 300]} // Límite del eje Y
              ticks={[0, 100, 200, 300]} // Valores específicos en el eje Y
            />
            <Tooltip
              cursor={{ fill: 'none' }} // Color del cursor
              contentStyle={{
                backgroundColor: 'white',
                border: 'none',
                borderRadius: '4px',
                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
              }}/>
            <Bar dataKey="count" fill="#81BFEC" radius={[4, 4, 0, 0]} />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default CandidatesEnrolledByDay;
