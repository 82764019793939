import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import Auth from './auth/Auth'
import Company from './pages/company/Company'
import CreateOffers from './offers/CreateOffers'
import GetOffers from './offers/GetOffers'
import GetOfferSave from './offers/GetOfferSave'
import Skills from './offers/skills/Skills'

const createRootReducer = (history: any) => combineReducers({
  router: connectRouter(history),
  auth: Auth,
  company: Company,
  createOffers: CreateOffers,
  getOffers: GetOffers,
  getOfferSave: GetOfferSave,
  skills: Skills
});

export default createRootReducer;
