import React, { useState } from 'react';
import { Modal, Divider, Tooltip } from 'antd';
import QRCodeImage from '../../../assets/img/modals/QR.svg'; // Importa la imagen del QR
import IconClosed from '../../../assets/icons/IconClosed.svg'; // Importa el ícono de cerrar
import IconCopy from '../../../assets/icons/IconCopy.svg'; // Importa el ícono de copiar
import InputC from '../../pages/offers/KillerQuestions/ComponentsKillersQ/Input'; // Importa el componente InputC

interface ValuationModalProps {
  visible: boolean;
  onClose: () => void;
}

const ValuationModal: React.FC<ValuationModalProps> = ({ visible, onClose }) => {
  const [copied, setCopied] = useState(false);

  const handleCopyLink = () => {
    navigator.clipboard.writeText('http://www.Merēre.es/profile:012345...');
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Muestra el mensaje de copiado por 2 segundos
  };

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      footer={null}
      centered
      closable={false}
      width={677} // Ajusta el ancho del modal a 677px
      bodyStyle={{
        padding: '0px', // Elimina el padding predeterminado
        position: 'relative',
      }}
      style={{
        borderRadius: '12px',
      }}
    >
      {/* Botón de cerrar con ícono personalizado */}
      <img 
        src={IconClosed} 
        alt="Cerrar" 
        onClick={onClose} 
        style={{
          position: 'absolute',
          top: '8px',
          right: '16px',
          cursor: 'pointer',
          width: '24px', // Ajusta el tamaño según sea necesario
          height: '24px'
        }}
      />
      
      <div className="mb-[46px]">
        <div className='mt-[39px] mx-[68px] text-center'>
            <h2 className="text-heading-md font-bold">Invita a alguien a que haga una valoración</h2>
            <img src={QRCodeImage} alt="QR Code" className="my-[38px] mx-auto" style={{ width: '152px', height: '181px' }} />
            <p className='font-medium text-body-md'>¡Escanea y comparte tu QR para que puedan valorarte!</p>
        </div>
        <Divider className="bg-gray-300" />
        <div className="mt-[37px] mx-[52px] ">
          <h3 className="text-heading-x1 font-medium text-[#BC9C9C] mb-[14px]">Nueva valoración</h3>
          <div className="relative">
            {/* Contenedor para el tooltip y el input */}
            <Tooltip title="http://www.Merēre.es/profile:01234555" placement="top">
              <InputC
                value="http://www.Merēre.es/profile:012345..."
                readOnly
                style={{
                  width: '100%', // Ajusta el ancho del input
                  whiteSpace: 'nowrap', // Evita que el texto se divida en varias líneas
                  overflow: 'hidden', // Oculta el texto que excede el ancho del input
                  textOverflow: 'ellipsis', // Muestra '...' cuando el texto es demasiado largo
                }}
              />
            </Tooltip>
            {/* Contenedor para el texto "Copiar enlace" y el ícono */}
            <div 
              onClick={handleCopyLink} 
              style={{
                position: 'absolute',
                top: '50%',
                right: '10px',
                transform: 'translateY(-50%)',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <span className="mr-[12px] text-green12 text-body-md font-medium">Copiar enlace</span>
              <img src={IconCopy} alt="Copiar" style={{ width: '20px', height: '20px' }} />
            </div>
          </div>
          {copied && <p className="text-green-500 text-sm mt-2">¡Enlace copiado!</p>}
        </div>
      </div>
    </Modal>
  );
};

export default ValuationModal;
