import React, { useState } from "react";
import { Modal, Button, Input } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import IconClosed from "../../../../assets/icons/IconClosed.svg";
import ToggleSwitch from "../../toggleSwitch/ToggleSwitch";
import dayjs, { Dayjs } from "dayjs";
import CustomDatePicker from "../../../components/pages/myPortal/Date"; // Importa tu CustomDatePicker

interface ModalEditWorkProps {
  visible: boolean;
  onClose: () => void;
  defaultWorking?: boolean;  // Añadimos esta prop
}

const ModalEditWork: React.FC<ModalEditWorkProps> = ({ visible, onClose, defaultWorking = false }) => {
  const [isCurrentlyWorking, setIsCurrentlyWorking] = useState(defaultWorking); // Usamos la prop para inicializar el estado
  const [startDate, setStartDate] = useState<string | null>(null);
  const [dateInput, setDateInput] = useState<string>("");

  // Función para manejar el cambio de fecha
  const handleDateChange = (date: Dayjs | null) => {
    if (date) {
      const formattedDate = date.format("MM/DD/YYYY"); // Formato de fecha
      setStartDate(formattedDate);
      setDateInput(formattedDate); // Asigna la fecha seleccionada al input
    }
  };

  return (
    <>
      <div>
        <div className="flex justify-between items-center mb-2">
          <h2 className="mb-3 text-body-md font-normal text-gray-800">
            ¿Sigues trabajando aquí?
          </h2>
          <ToggleSwitch
            defaultChecked={isCurrentlyWorking}
            size="small"
            customClass="switch-small-1"
            onChange={() => setIsCurrentlyWorking(!isCurrentlyWorking)}
          />
        </div>

        {isCurrentlyWorking ? (
          <div className="flex space-x-[19px]">
            <CustomDatePicker
              useInput={true} // Esto asegura que se utilice el Input separado
              // onChange={handleDateChange}
              // value={startDate ? dayjs(startDate, "MM/DD/YYYY") : null}
            />
          </div>
        ) : (
          <div className="flex space-x-[115px]">
            <CustomDatePicker
              useInput={false} // Sin Input separado
              // onChange={handleDateChange}
              // value={startDate ? dayjs(startDate, "MM/DD/YYYY") : null}
              // placeholder="Fecha inicio"
            />
            <CustomDatePicker
              useInput={false} // Sin Input separado
              // onChange={handleDateChange}
              // value={startDate ? dayjs(startDate, "MM/DD/YYYY") : null}
              // placeholder="Fecha fin"
            />
          </div>
        )}
      </div>
    </>
  );
};

export default ModalEditWork;
