import React, { useState } from "react";
import { Row, Col, Card, Divider, Drawer, Button } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import Header from "../../../components/pages/principalNav/HeaderOffers";
import { useParams, useNavigate, Link} from "react-router-dom";
import OffersProfileInfo from "../../../components/pages/offers/offersProfile/OffersProfileInfo";
import SidebarOffers from "../../../components/pages/offers/offersProfile/SidebarOffersProfile";
import Avatar1 from "../../../assets/img/offers/Avatar.png";
import Stream from "../../../assets/icons/view_stream.svg";
import List from "../../../assets/icons/view_list.svg";
import IconButton from "../../../components/pages/searchCV/IconButton";

const OffersProfile: React.FC = () => {
  // Obtener el parámetro de la URL usando useParams
  const { id }: any = useParams();
  const [selectedView, setSelectedView] = useState<string>("stream");
  // Convertir el parámetro de la URL en un número entero
  const offerId: any = parseInt(id, 10);
  const [drawerVisible, setDrawerVisible] = useState(false);


  // Verificar el valor de offerId en la consola
  console.log("offerId:", offerId);

  // Declarar el hook useNavigate para la navegación
  const navigate = useNavigate();

  const handleViewSelect = (view: string) => {
    setSelectedView(view);
  };

  // Definición del objeto profileInfo con los datos de los candidatos
  // const profileInfo = {
  //   1: [
  //     {
  //       offerId: 1,
  //       candidateId: 1, // Asegúrate de que candidateId es un número
  //       avatarUrl: Avatar1,
  //       name: "Elena Rodriguez",
  //       role: "Reponedor",
  //       location: "Alcazar de San Juan",
  //       rating: 3,
  //       reviewsLink: "Ver valoraciones",
  //       experiences: [
  //         "Cajero de supermercado | Supermercados Carrefour | jun. 2022 - abr. 2023 (14 meses)",
  //         "Ayudante de charcutería | Eroski | may. 2023 - feb. 2024 (15 meses)"
  //       ],
  //       education: [
  //         "Bachillerato de arte | Escuela de artes de Salamanca | jun. 2022 - abr. 2023",
  //         "Curso de atención al cliente y ventas | Supermercados Carrefour | may. 2023 - feb. 2024"
  //       ],
  //       tags: ["Cajero", "Supermercado"],
  //       language: ["Ingles C1", "Catalan B2"],
  //       status: ["Búsqueda activa | Remoto admitido | Jornada completa"],
  //       cvLink: "Ver CV en pdf",
  //       killerQuestions: "Killer questions 7/10",
  //       aptitudes: ["Cajera"],
  //       onSelectAnswer: (value: any) => console.log("Respuesta seleccionada:", value),
  //     },
  //     {
  //       offerId: 1,
  //       candidateId: 2, // Asegúrate de que candidateId es un número
  //       avatarUrl: Avatar1,
  //       name: "Elena Rodriguez",
  //       role: "Reponedor",
  //       location: "Alcazar de San Juan",
  //       rating: 3.2,
  //       reviewsLink: "Ver valoraciones",
  //       experiences: [
  //         "Cajero de supermercado | Supermercados Carrefour | jun. 2022 - abr. 2023 (14 meses)",
  //         "Ayudante de charcutería | Eroski | may. 2023 - feb. 2024 (15 meses)"
  //       ],
  //       education: [
  //         "Bachillerato de arte | Escuela de artes de Salamanca | jun. 2022 - abr. 2023",
  //         "Curso de atención al cliente y ventas | Supermercados Carrefour | may. 2023 - feb. 2024"
  //       ],
  //       tags: ["Cajero", "Supermercado"],
  //       language: ["Ingles C1", "Catalan B2"],
  //       status: ["Búsqueda activa | Remoto admitido | Jornada completa"],
  //       cvLink: "Ver CV en pdf",
  //       killerQuestions: "Killer questions 7/10",
  //       aptitudes: ["Cajera"],
  //       onSelectAnswer: (value: any) => console.log("Respuesta seleccionada:", value),
  //     },
  //     // Más perfiles...
  //   ],
  // };

  const [profileInfo, setProfileInfo] = useState<any>({
    1: [
      {
        offerId: 1,
        candidateId: 1, // Asegúrate de que candidateId es un número
        avatarUrl: Avatar1,
        name: "Elena Rodriguez",
        role: "Reponedor",
        location: "Alcazar de San Juan",
        rating: 3,
        reviewsLink: "Ver valoraciones",
        experiences: [
          "Cajero de supermercado | Supermercados Carrefour | jun. 2022 - abr. 2023 (14 meses)",
          "Ayudante de charcutería | Eroski | may. 2023 - feb. 2024 (15 meses)"
        ],
        education: [
          "Bachillerato de arte | Escuela de artes de Salamanca | jun. 2022 - abr. 2023",
          "Curso de atención al cliente y ventas | Supermercados Carrefour | may. 2023 - feb. 2024"
        ],
        tags: ["Cajero", "Supermercado"],
        language: ["Ingles C1", "Catalan B2"],
        status: ["Búsqueda activa | Remoto admitido | Jornada completa"],
        cvLink: "Ver CV en pdf",
        killerQuestions: "Killer questions 7/10",
        aptitudes: ["Cajera"],
        onSelectAnswer: (value: any) => console.log("Respuesta seleccionada:", value),
      },
      {
        offerId: 1,
        candidateId: 2, // Asegúrate de que candidateId es un número
        avatarUrl: Avatar1,
        name: "Elena Rodriguez",
        role: "Reponedor",
        location: "Alcazar de San Juan",
        rating: 3.2,
        reviewsLink: "Ver valoraciones",
        experiences: [
          "Cajero de supermercado | Supermercados Carrefour | jun. 2022 - abr. 2023 (14 meses)",
          "Ayudante de charcutería | Eroski | may. 2023 - feb. 2024 (15 meses)"
        ],
        education: [
          "Bachillerato de arte | Escuela de artes de Salamanca | jun. 2022 - abr. 2023",
          "Curso de atención al cliente y ventas | Supermercados Carrefour | may. 2023 - feb. 2024"
        ],
        tags: ["Cajero", "Supermercado"],
        language: ["Ingles C1", "Catalan B2"],
        status: ["Búsqueda activa | Remoto admitido | Jornada completa"],
        cvLink: "Ver CV en pdf",
        killerQuestions: "Killer questions 7/10",
        aptitudes: ["Cajera"],
        onSelectAnswer: (value: any) => console.log("Respuesta seleccionada:", value),
      },
      // Más perfiles...
    ],
  });

  // Obtener los perfiles actuales según offerId
  const currentProfileInfos: any = profileInfo[offerId];

  // Manejo del clic en el candidato para la navegación
  const handleCandidateClick = (candidateId: number) => {
    const path = `/offers/${offerId}/candidate/${candidateId}`;
    console.log("Navigating to:", path); // Verificar la ruta a la que se navega
    navigate(path); // Navegar a la nueva ruta
  };

  return (
    <div>
      {/* Componente de cabecera */}
      <Header />
      <div className="px-4 py-6">
        <Row gutter={16}>
          {/* Botón para abrir el Drawer en pantallas pequeñas */}
          <Button
            type="primary"
            className="md:hidden mb-4" // Mostrar solo en pantallas pequeñas
            onClick={() => setDrawerVisible(true)}
            icon={<MenuOutlined />}
          />

          {/* Sidebar visible en pantallas grandes */}
          <div style={{ width: "234px", backgroundColor: "white", marginLeft:"15px", marginRight:"10px" }} className="hidden md:block p-0">
            <SidebarOffers />
          </div>

          {/* Drawer para pantallas pequeñas */}
          <Drawer
            title={null}
            drawerStyle={{ padding: 0 }} // Elimina el padding de la estructura del Drawer
            width={280}
            placement="left"
            onClose={() => setDrawerVisible(false)}
            open={drawerVisible}
            className="block md:hidden" // Mostrar solo en pantallas pequeñas
          >
            <SidebarOffers />
          </Drawer>

          <Col xs={24} md={16} lg={19}>
            <div className="mb-6">
              <div className="flex justify-between items-center">
                <div>
                <h2
                  onClick={() => window.location.href = '/offers'}
                  className="font-bold text-[#006497] text-2xl inline cursor-pointer"
                >
                  Oferta
              </h2>

                  <span className="font-bold text-[#1A1A1A70] text-2xl mx-2">&gt;</span>
                  <span className="font-bold text-black text-2xl inline">Listado de inscritos en la oferta</span>
                </div>
              </div>
              <Divider className="my-4 bg-[#A1A1AA]" />
              <div className="flex justify-between items-center mb-4">
                <div>
                  <h3 className="text-sm font-medium text-black">Título de la oferta</h3>
                  <p className="text-lg font-medium text-black">Responsable de turno en supermercado</p>
                </div>
                <Button
                  className="principal-nav-notify-button w-[150px] h-[36px] font-semibold text-body-sm rounded-[4px]"
                  onClick={() => navigate("")} // Change path to subroute
                >
                  Guardar búsqueda
                </Button>
              </div>
              <Row gutter={16} className="mb-[30px]">
                <Col xs={24} md={16} lg={24} className="">
                  <Row gutter={24} className="">
                    <Col xs={12}>
                      <span className="font-bold text-[#1A1A1A70] text-xs">Ordenado por:</span>
                      <div className="mt-2">
                        <span className="font-bold text-[#00476D] text-xs">RECOMENDACIÓN IA</span>
                        <span className="font-bold text-[#1A1A1A70] text-xs mx-2">|</span>
                        <span className="font-bold text-[#1A1A1A70] text-xs">KILLER QUESTIONS</span>
                        <span className="text-xs text-[#00476D] ml-1">&#9660;</span>
                        <span className="font-bold text-[#1A1A1A70] text-xs mx-2">|</span>
                        <span className="font-bold text-[#00476D] text-xs">FECHA DE INSCRIPCIÓN</span>
                      </div>
                    </Col>
                    <Col xs={12} className="flex justify-end items-center">
                      {/* Align to the right */}
                      <IconButton
                        icon={Stream}
                        selected={selectedView === "stream"} // Check if 'stream' is selected
                        onClick={() => handleViewSelect("stream")}
                        altText="Icono de flujo"
                      />
                      <IconButton
                        icon={List}
                        selected={selectedView === "list"} // Check if 'list' is selected
                        onClick={() => handleViewSelect("list")}
                        altText="Icono de lista"
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>

              <div className="flex justify-between items-center mb-4">
                <span className="font-bold text-[#1A1A1A70] text-xs mr-2">ACCIONES DE PERFIL:</span>
                <div className="flex space-x-2">
                  <button className="text-[#00476D] font-bold text-xs hover:underline">Añadir a favoritos</button>
                  <span className="font-bold text-xs">|</span>
                  <button className="text-[#00476D] font-bold text-xs hover:underline">Avanzar en la fase</button>
                  <span className="font-bold text-xs">|</span>
                  <button className="text-[#00476D] font-bold text-xs hover:underline">Guardar perfil</button>
                  <span className="font-bold text-xs">|</span>
                  <button className="text-red-500 font-bold text-xs hover:underline">Archivar candidato</button>
                </div>
                <button className="text-[#00476D] font-bold text-xs hover:underline ml-auto">
                  Ver candidatos seleccionados sobre gráfica
                </button>
              </div>
            </div>
            {currentProfileInfos ? (
              <Card className="border-[#81BFEC] bg-[#FBFDFE] rounded-md p-4 mb-4">
                {currentProfileInfos.map((profile: any) => (
                  // Asegúrate de usar candidateId como la clave única
                  <React.Fragment key={profile.candidateId}>
                    <OffersProfileInfo
                      {...profile}
                      // Llama a handleCandidateClick con candidateId
                      onClick={() => handleCandidateClick(profile.candidateId)}
                    />
                    <Divider className="bg-black" />
                  </React.Fragment>
                ))}
              </Card>
            ) : (
              <p>Oferta no encontrada</p>
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default OffersProfile;