import React from 'react';
import { Row, Col } from 'antd';
import { useNavigate } from 'react-router-dom';
import Header from '../../../components/pages/principalNav/PrincipalNav';
import CompanySidebar from '../../../components/pages/company/CompanySidebar';
import CardEmpresa from '../../../components/cards/CardCompanyButtons';
import ArrowLeft from '../../../assets/img/offers/ArrowLeft.svg';

const FollowedCompanies: React.FC = () => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1); // Regresa a la vista anterior
  };

  try {
    return (
      <>
        <Header />
        <div className="followed-companies-container" style={{ padding: '0 10px', paddingLeft: '30px', margin: '0 auto' }}>
          <Row align="middle" style={{ margin: '16px 0' }}>
            <img
              src={ArrowLeft}
              alt="Arrow Left"
              style={{ marginRight: '8px', cursor: 'pointer' }}
              onClick={handleBackClick}
            />
            <span style={{ fontSize: '24px', fontWeight: 'bold' }}>Empresas que sigues</span>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xl={19} lg={19} md={24}>
              <CardEmpresa
                title="Nombre de la Empresa"
                description="Descripción de la empresa"
                location="Ubicación"
                link="https://empresa.com"
                avatarUrl="https://empresa.com/logo.png"
                reviews={10}
                activeOffers={5}
                followers={200}
                workers={100}
                categories={['Categoría 1', 'Categoría 2']}
                cardType={4}
                rating={5.0}
                onClick={() => console.log('Card clicked')}
              />
              <CardEmpresa
                title="Nombre de la Empresa"
                description="Descripción de la empresa"
                location="Ubicación"
                link="https://empresa.com"
                avatarUrl="https://empresa.com/logo.png"
                reviews={10}
                activeOffers={5}
                followers={200}
                workers={100}
                categories={['Categoría 1', 'Categoría 2']}
                cardType={4}
                rating={5.0}
                onClick={() => console.log('Card clicked')}
              />
            </Col>
            <Col xl={5} lg={5} md={24}>
              <CompanySidebar />
            </Col>
          </Row>
        </div>
      </>
    );
  } catch (error) {
    console.error('Error en FollowedCompanies:', error);
    return <div>Algo salió mal</div>;
  }
};

export default FollowedCompanies;
