import React from 'react';
import ChartDonut from '../../chartDonut/ChartDonut';
import LegendGroup from '../../legendGroup/LegendGroup';
import { Row, Col } from 'antd';
import RatingBlue from '../../rating/RatingBlue'; // Importa el componente RatingBlue

interface CompanyRatingsProps {
  totalRatings: number;
  rating: number;
  labels: string[];
  recommendPercentage: number;
  recruiterScore: number;
}

const CompanyRatings: React.FC<CompanyRatingsProps> = ({
  totalRatings,
  rating,
  labels,
  recommendPercentage,
  recruiterScore,
}) => {
  const donutData = [
    { name: 'Sección 1', value: 50 },
    { name: 'Sección 2', value: 20 },
    { name: 'Sección 3', value: 10 },
    { name: 'Sección 4', value: 20 },
    { name: 'Sección 5', value: 15 },
  ];

  const smallDonutData1 = [
    { name: 'Recomienda', value: 70 },
    { name: 'No Recomienda', value: 30 },
  ];

  const smallDonutData2 = [
    { name: 'Alta Nota', value: 92 },
    { name: 'Baja Nota', value: 8 },
  ];

  // Opciones para las etiquetas
  const options = [
    { label: 'Label', value: 'label1' },
    { label: 'Label', value: 'label2' },
    { label: 'Label', value: 'label3' },
    { label: 'Label', value: 'label4' },
    { label: 'Label', value: 'label5' },
    { label: 'Label', value: 'label6' },
  ];

  return (
    <div className="border border-blue3 bg-blue5 rounded-lg pt-[26px] pl-[32px] pb-[26px]">
      <Row gutter={[24, 24]}>
        {/* Sección Izquierda */}
        <Col xs={24} md={12} className="flex">
          <div className="flex items-center">
            <div>
              <h3 className="text-lg text-blue3 ml-1 mb-[-32px] text-left font-bold">Valoraciones</h3>
              <div className="flex justify-left items-center -ml-6 mt-4">
                <ChartDonut
                  data={donutData}
                  width={200}
                  height={200}
                  innerRadius={60}
                  outerRadius={75}
                  paddingAngle={1}
                  styleType="default"
                  text="120"
                  label="Valoraciones"
                  sections={5}
                  showContainer={false}
                />
              </div>
            </div>
            {/* Etiquetas (Labels) en 3 filas y 2 columnas */}
            <div className="ml-4 mt-2 mr-[-40px] flex flex-col items-start">
              <Row gutter={[16, 16]}>
                {options.map((option, index) => (
                  <Col span={12} key={index}>
                    <LegendGroup options={[option]} defaultCheckedList={[]} />
                  </Col>
                ))}
              </Row>
            </div>
          </div>
        </Col>

        {/* Sección Derecha */}
        <Col xs={24} md={12} className="flex flex-col items-start">
          <div className="flex items-center mb-4">
            <span className="text-xl font-bold ml-8 mb-4 mr-4">5.0</span>
           <div className='mt-[-20px]'> 
              <RatingBlue 
                filledStars={5} 
                showRatingValue={false} 
                filledStarSize={{ width: '20px', height: '20px', marginRight: '2px', marginTop: '2px' }}
                emptyStarSize={{ width: '24px', height: '24px', marginLeft: '-2px' }}
              />
            </div>
          </div>
          <div className="flex items-center mt-[-40px] mb-[-16px] ml-4">
            <ChartDonut
              data={smallDonutData1}
              width={100}
              height={100}
              innerRadius={15}
              outerRadius={28}
              paddingAngle={1}
              styleType="default"
              text=""
              label=""
              sections={2}
              showContainer={false}
            />
            <div className="-ml-2 text-left flex items-center">
              <span className="font-medium text-lg mr-2">70%</span>
              <span className="text-sm text-black opacity-70">Recomienda<br />trabajar aquí</span>
            </div>
          </div>
          <div className="flex items-center ml-4">
            <ChartDonut
              data={smallDonutData2}
              width={100}
              height={100}
              innerRadius={15}
              outerRadius={28}
              paddingAngle={1}
              styleType="default"
              text=""
              label=""
              sections={2}
              showContainer={false}
            />
            <div className="-ml-2 text-left flex items-center">
              <span className="font-medium text-lg mr-2">92%</span>
              <span className="text-sm text-black opacity-70">Nota del<br />reclutador</span>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default CompanyRatings;
