import React, { useState } from "react";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import { Row, Col, Button, Card, Drawer, Layout } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import Header from "../../components/pages/principalNav/HeaderOffers";
import SidebarSetting from "../../components/pages/offers/SidebarOffers";
import StatCard from "../../components/pages/controlPanel/ButtonsControlP"; // Correct the import path
import Agenda from "../../components/pages/controlPanel/Calendar"; // Correct the import path
import LatestRegistrants from "../../components/pages/controlPanel/LatestRegistrations"; // Correct the import path
import OpenOffers from "../../components/pages/controlPanel/OpenOffers";
import CandidateSummary from "../../components/pages/controlPanel/CandidateSummary";
import CandidatesRegistered from "../../components/pages/controlPanel/RegisteredCandidates";
import CompletedOffers from "./SummaryActivity/CompletedOffers";
import SavedSearches from "./SummaryActivity/SavedSearches";
import LatesSearches from "./SummaryActivity/LatestSearches";

const { Sider, Content } = Layout;

const Offers: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [drawerVisible, setDrawerVisible] = useState(false);

  return (
    <>
      <Header />
      <div className="bg-white px-[24px] py-[48px]">
        <div className="flex">
          {/* Sidebar visible en pantallas grandes */}
          <Sider
            width={234}
            breakpoint="md" // Cambia a "md" para 768px o "lg" para 1024px
            collapsedWidth="0"
            style={{ backgroundColor: "white", zIndex: 1 }} // Asegúrate de que tenga un zIndex alto si es necesario
            className="hidden md:block p-0" // Ocultar en pantallas pequeñas, mostrar en medianas o grandes
          >
            <SidebarSetting title="Panel de control" /> {/* Aquí pasamos el prop title */}
          </Sider>

          <Content className="flex-1 pl-[24px]">
            {/* Stat Cards */}
            <Button
              type="primary"
              className="lg:hidden mb-4" // Mostrar solo en pantallas pequeñas
              onClick={() => setDrawerVisible(true)}
              icon={<MenuOutlined />}
            />
            <Row gutter={[16, 16]} className="mb-8">
              <Col xl={6} xs={24} sm={12} md={6}>
                <StatCard
                  title="Reponedor de supermercado"
                  value={234}
                  description="inscritos hoy"
                />
              </Col>
              <Col xl={6} xs={24} sm={12} md={6}>
                <StatCard
                  title="Nuevos candidatos"
                  value={98}
                  description="últimas 24h"
                />
              </Col>
              <Col xl={6} xs={24} sm={12} md={6}>
                <StatCard
                  title="Reponedor de supermercado"
                  value={234}
                  description="inscritos hoy"
                />
              </Col>
              <Col xl={6} xs={24} sm={12} md={6}>
                <StatCard
                  title="Pricing"
                  value={13}
                  description="CV por descubrir"
                />
              </Col>
            </Row>

            {/* Main Dashboard */}
            <Row gutter={[16, 16]}>
              <Col xs={24} md={24}>
                <Card className="border border-sky-blue0 rounded-[6px]">
                  <Row gutter={[16, 16]} className="pb-[26px]">
                    <Col xs={24} sm={12} md={12}>
                      <div className="border border-sky-200 rounded-md w-[462] h-[561px]">
                        <h3 className="font-bold text-heading-x1 pb-[22px]">
                          Agenda
                        </h3>
                        <Agenda />
                      </div>
                    </Col>
                    <Col xs={24} sm={12} md={12}>
                      <div className="w-[377] h-[195px] mb-[80px]">
                        <CandidateSummary />
                      </div>
                      <div className="w-[439] h-[285px]">
                        <CandidatesRegistered />
                      </div>
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col xs={24} sm={12} md={12}>
                      <div className="border border-sky-200 px-4 py-6 rounded-md w-[463] h-[306px]">
                        <OpenOffers />
                      </div>
                    </Col>
                    <Col xs={24} sm={12} md={12}>
                      <div className="border border-sky-200 px-4 py-6 rounded-md w-[463] h-[306px]">
                        <LatestRegistrants />
                      </div>
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={24}>
                      <h3 className="font-bold text-heading-x1 py-[26px]">
                        Resumen de tu actividad
                      </h3>
                      <div className="tabs-merere">
                        <div className="tab-links-merere flex justify-center px-4">
                          <button
                            className={`tab-link-merere ${
                              location.pathname ===
                              "/controlPanel/completeOffers"
                                ? "active-merere"
                                : ""
                            }`}
                            onClick={() =>
                              navigate("/controlPanel/completeOffers")
                            }
                          >
                            <span className="inline">Últimas Búsquedas</span>
                          </button>
                          <button
                            className={`tab-link-merere ${
                              location.pathname ===
                              "/controlPanel/savedSearches"
                                ? "active-merere"
                                : ""
                            }`}
                            onClick={() =>
                              navigate("/controlPanel/savedSearches")
                            }
                          >
                            <span className="inline">Búsquedas guardadas</span>
                          </button>
                          <button
                            className={`tab-link-merere ${
                              location.pathname ===
                              "/controlPanel/latesSearches"
                                ? "active-merere"
                                : ""
                            }`}
                            onClick={() =>
                              navigate("/controlPanel/latesSearches")
                            }
                          >
                            <span className="inline">Ofertas finalizadas</span>
                          </button>
                        </div>

                        {/* Contenido de las tabs */}
                        <div className="tab-content-merere p-4">
                          <Routes>
                            <Route
                              path="completeOffers"
                              element={<CompletedOffers />}
                            />
                            <Route
                              path="savedSearches"
                              element={<SavedSearches />}
                            />
                            <Route
                              path="latesSearches"
                              element={<LatesSearches />}
                            />
                          </Routes>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Content>
        </div>
      </div>

      <Drawer
        title={null} // Elimina el título del Drawer
        placement="left"
        onClose={() => setDrawerVisible(false)}
        open={drawerVisible}
        // Elimina el padding del contenido del Drawer
        drawerStyle={{ padding: 0 }} // Elimina el padding de la estructura del Drawer
        width={280} // Ajusta el ancho para que coincida con el ancho del sidebar
        className="block md:hidden custom-drawer" // Añade una clase personalizada para estilos adicionales
      >
        <SidebarSetting title="Panel de control" /> {/* Aquí pasamos el prop title */}
      </Drawer>
    </>
  );
};

export default Offers;
