import React, { useState } from "react";
import { Row, Col, Typography, Checkbox, Slider, Radio } from "antd"; // Make sure Radio is imported
import { PlusOutlined } from "@ant-design/icons";
import QuestionTypeSelect from "./ComponentsKillersQ/QuestionTypeSelect";
import InputC from "./ComponentsKillersQ/Input";
import CustomInputNumber from "./ComponentsKillersQ/CustomInputNumber";
import InputNumber from "./ComponentsKillersQ/InputNumber";
import CheckboxC from "../../../../components/checkbox/CheckboxProps";
import CustomRadio from "./ComponentsKillersQ/CustomRadio"; // Import the CustomRadio component
import CheckQuestion from "./ComponentsKillersQ/CheckQuestion"; // Import the CheckQuestion component
import Ckeditor from '../../../../components/pages/offers/CreateOffers/Ckeditor';

const { Title } = Typography;

const CustomSlider = () => {
  const [value, setValue] = useState(1);

  return (
    <div className="max-w-full md:max-w-[420px]">
      <div className="flex justify-between mb-2">
        <span>Pequeño</span>
        <span>Mediano</span>
        <span>Grande</span>
      </div>
      <Slider
        min={0}
        max={2}
        step={1}
        value={value}
        onChange={setValue}
        tooltip={{ formatter: null }}
        trackStyle={{ backgroundColor: "#0778b1" }}
        railStyle={{ backgroundColor: "#d9d9d9" }}
      />
    </div>
  );
};

const KillerQuestionItem = ({
  number,
  title,
  placeholder,
  additionalContent,
  defaultSelectedOption = "Margen numérico",
}: any) => {
  const [selectedOption, setSelectedOption] = useState(defaultSelectedOption);
  const [numValue1, setNumValue1] = useState(0);
  const [numValue2, setNumValue2] = useState(2);

  // Manejamos la lista de opciones para las casillas
  const [checkOptions, setCheckOptions] = useState<string[]>([
    "Manejo de la caja y transacciones financieras",
    "Atención directa y asesoramiento a clientes",
    "Organización de productos en las áreas cercanas a la caja",
    "Resolución de incidencias y devoluciones de clientes",
  ]);

  // Función para añadir una nueva opción
  const handleAddCheckOption = () => {
    const newOption = `Nueva opción ${checkOptions.length + 1}`;
    setCheckOptions([...checkOptions, newOption]);
  };

  const renderAdditionalContent = () => {
    switch (selectedOption) {
      case "Escala lineal":
        return <CustomSlider />;
      case "Elegir opción":
        return (
          <Radio.Group className="mt-4 font-bold text-body-md">
            <CustomRadio value="si">Sí</CustomRadio>
            <CustomRadio value="no">No</CustomRadio>
          </Radio.Group>
        );
      case "Respuesta personalizada":
        return null;
      case "Diferentes casillas":
        return (
          <CheckQuestion
            options={checkOptions}
            onAddOption={handleAddCheckOption}
            showAddOption={true}
          />
        );
      default:
        return (
          <div className="flex flex-wrap items-center">
            <CustomInputNumber value={numValue1} onChange={setNumValue1} />
            <h1 className="mt-0 pl-[43px] mr-[55px] font-medium text-gray">
              <PlusOutlined
                onClick={() => console.log("Plus button clicked")}
                className="text-blue3 text-body-sm pr-[8px]"
              />
              Añadir campo numérico
            </h1>

            <CheckboxC value={0} children={undefined} />
            <h1 className="text-gray-600 font-medium text-gray mr-[0px]">
              Descartar candidato si es inferior a
            </h1>
            <InputNumber value={numValue2} onChange={setNumValue2} />
          </div>
        );
    }
  };

  return (
    <div className="mb-8 p-4">
      <Title level={4} className="font-bold text-heading-x1 mb-2 text-center md:text-left">
        {number}. {title}
      </Title>
      <Row gutter={[16, 16]}>
        <Col xs={24} md={15}>
          {/*<InputC  placeholder={placeholder} customClassName='my-[16px]' />*/}
          <div className="w-full mb-4"><Ckeditor mode="killerQuestions" placeholder={placeholder} /></div>
        </Col>
        <Col xs={24} md={9} className="pt-[15px]">
          <QuestionTypeSelect
            onChange={setSelectedOption}
            defaultValue={selectedOption}
          />
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col xs={24}>{renderAdditionalContent()}</Col>
      </Row>
    </div>
  );
};

export default KillerQuestionItem;