import { Action, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { RootState } from '../../store/store';
import {
  FETCH_SAVE_OFFER,
  FETCH_FAILED_SAVE_OFFER,
  FETCH_RESET_SAVE_OFFER,
  FETCH_SUCCESS_SAVE_OFFER
} from '../../../constants/offers/SaveOffer';
const API_URL = process.env.REACT_APP_API_BASE_URL;

export const SaveOfferReducer = (
  offerId: number): ThunkAction<
    Promise<void>,
    RootState,
    unknown,
    Action<string>
  > => async (dispatch: Dispatch, getState) => {

    const {
      rex_user
    } = getState().auth

    dispatch({
      type: FETCH_SAVE_OFFER
    })

    try {

      const data = await fetch(API_URL + 'postulaciones-guardadas', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          oferta_id: offerId,
          usuario_id: rex_user?.id
        }),
      }).then(res => res.json());

      dispatch({
        type: FETCH_SUCCESS_SAVE_OFFER,
        payload: data
      })

      return data

    } catch (error) {
      dispatch({
        type: FETCH_FAILED_SAVE_OFFER
      })
      throw error;
    }
  };


export const ResetCreateOfferReducer = (): ThunkAction<
  Promise<void>,
  RootState,
  unknown,
  Action<string>
> => async (dispatch: Dispatch) => {
  dispatch({
    type: FETCH_RESET_SAVE_OFFER
  })
}