import React, { useState, useEffect, useRef } from "react";
import { Modal, Button, Input } from "antd";
import IconClosed from "../../../../assets/icons/IconClosed.svg";
import ModalSaved from "../../myPortal/Modals/ModalSavedChanges";

interface ModalAddMultimediaProps {
  visible: boolean;
  onClose: () => void;
  onSave: (file: File) => void;  // Callback para guardar el archivo
}

const ModalAddMultimedia: React.FC<ModalAddMultimediaProps> = ({
  visible,
  onClose,
  onSave,
}) => {
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [isSecondModalVisible, setIsSecondModalVisible] = useState(false);

  // Ref para el input de archivo
  const fileInputRef = useRef<any>(null);

  useEffect(() => {
    if (!visible) {
      setUploadedFile(null);
      setIsSubmitDisabled(true);
      // Restablecer el campo de selección de archivo
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    }
  }, [visible]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setUploadedFile(e.target.files[0]);
      setIsSubmitDisabled(false);
    }
  };

  const handleSaveChanges = () => {
    if (uploadedFile) {
      onSave(uploadedFile);  // Llama al callback para guardar el archivo
    }
    setIsSecondModalVisible(true); // Muestra el modal de confirmación
  };

  const handleModalSavedClose = () => {
    setIsSecondModalVisible(false);
    onClose(); // Cierra este modal
  };

  return (
    <>
      <Modal
        open={visible}
        onCancel={onClose}
        footer={null}
        centered
        closable={false}
        width={677}
        bodyStyle={{ borderRadius: "12px" }}
        style={{
          borderRadius: "12px",
          border: "1px solid #E1E1E2",
          marginTop: "15px",
          marginBottom: "15px",
        }}
      >
        <img
          src={IconClosed}
          alt="Cerrar"
          onClick={onClose}
          style={{
            position: "absolute",
            top: "34px",
            right: "34px",
            cursor: "pointer",
            width: "24px",
            height: "24px",
          }}
        />

        <div className="text-center mx-[86px] mt-[21px]">
          <h3 className="mt-[16px] mx-[44px] text-heading-md font-bold">
            Añadir contenido multimedia
          </h3>
          <p className="font-medium px-[55px] text-body-sm mt-[10px]">
            Por favor, a continuación edita tu información
          </p>
          <p className="font-normal px-[55px] text-body-md mt-[36px]">
            Añade fotos, presentaciones o documentos
          </p>
        </div>

        <div className="mx-[68px] mt-[43px]">
          <span className="text-body-md font-normal text-[#5F5F5F]">
            Añade fotos, presentaciones o documentos
          </span>
          <Input
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            accept=".jpg,.jpeg,.png,.pdf,.ppt,.pptx,.doc,.docx"
            style={{ marginTop: "10px", marginBottom: "23px" }}
          />
        </div>

        <div className="flex mx-[86px] mb-[67px] justify-center mt-[71px]">
          <Button
            onClick={onClose}
            className="principal-nav-notify-buttonG w-[118px] h-[44px]"
            style={{ marginRight: "8px", borderRadius: "4px" }}
          >
            Cancelar
          </Button>
          <Button
            disabled={isSubmitDisabled || !uploadedFile}
            onClick={handleSaveChanges}
            className={`w-[181px] h-[44px] ml-[18px] rounded-[4px] ${isSubmitDisabled ? "bg-[#F4F4F5] text-[#757575] font-semibold text-body-md cursor-not-allowed" : "bg-blue3 principal-nav-notify-button2 text-white cursor-pointer"}`}
          >
            Guardar cambios
          </Button>
        </div>
      </Modal>

      <ModalSaved
        visible={isSecondModalVisible}
        onClose={handleModalSavedClose}
      />
    </>
  );
};

export default ModalAddMultimedia;
