import React from 'react';
import { Row, Col } from 'antd';
import Profile from '../../../components/pages/myPortal/Profile';
import imageP from '../../../assets/img/MyPortal/ImageP.svg';
import imageP2 from '../../../assets/img/MyPortal/IImage.svg';

const profileData = {
  profileInfo: {
    name: 'Nombre',
    lastName: 'Primer apellido',
    lastNameP: 'Primer apellido',
    lastNameS: 'Segundo apellido',
    aboutMe:
      'Soy un entusiasta del mundo digital con una pasión por transformar datos en decisiones estratégicas. Con más de 5 años de experiencia en análisis de datos y machine learning, he colaborado en proyectos innovadores que han optimizado procesos y generado valor en diversas industrias. Mi formación en Ciencias de la Computación, complementada con una maestría en Big Data, me permite abordar desafíos complejos con una visión analítica y creativa. En el portal de empleo, busco oportunidades que desafíen mis habilidades técnicas mientras contribuyo al crecimiento y éxito de una empresa orientada al futuro.',
    skills: ['Office', 'Informática', 'Dependiente con experiencia'],
    experience: [
      {
        title: 'Cajero de supermercado',
        company: 'Supermercados Carrefour',
        location: 'Campo de Criptana',
        other: 'Retail',
        startDate: 'Inicio',
        endDate: 'Fin (trabajo aquí)',
        totalDate: 'Total',
        description: 'Descripción del puesto',
        ratings: 'Valoraciones',
        tags: 'Etiquetas',
      },
      {
        title: 'Ayudante de charcutería',
        company: 'Supermercados Carrefour',
        location: 'Campo de Criptana',
        other: 'Retail',
        startDate: 'Inicio',
        endDate: 'Fin (trabajo aquí)',
        totalDate: 'Total',
        description: 'Descripción del puesto',
        ratings: 'Valoraciones',
        tags: 'Etiquetas',
      },
      {
        title: 'Ayudante de charcutería',
        company: 'Supermercados Carrefour',
        location: 'Campo de Criptana',
        other: 'Retail',
        startDate: 'Inicio',
        endDate: 'Fin (trabajo aquí)',
        totalDate: 'Total',
        description: 'Descripción del puesto',
        ratings: 'Valoraciones',
        tags: 'Etiquetas',
      },
      {
        title: 'Ayudante de charcutería',
        company: 'Supermercados Carrefour',
        location: 'Campo de Criptana',
        other: 'Retail',
        startDate: 'Inicio',
        endDate: 'Fin (trabajo aquí)',
        totalDate: 'Total',
        description: 'Descripción del puesto',
        ratings: 'Valoraciones',
        tags: 'Etiquetas',
      },
    ],
    education: [
      {
        degree: 'Bachillerato de arte',
        school: 'Supermercados Carrefour',
        location: 'Campo de Criptana',
        others: 'Retail',
        startDate: 'Inicio',
        endDate: 'Fin (trabajo aquí)',
        totalDates: 'Total',
        description: 'Descripción del puesto',
        rating: 'Valoraciones',
        tag: 'Etiquetas',
      },
      {
        degree: 'Curso de atención al cliente y ventas',
        school: 'Supermercados Carrefour',
        location: 'Campo de Criptana',
        others: 'Retail',
        startDate: 'Inicio',
        endDate: 'Fin (trabajo aquí)',
        totalDates: 'Total',
        description: 'Descripción del puesto',
        rating: 'Valoraciones',
        tag: 'Etiquetas',
      },
      {
        degree: 'Curso de atención al cliente y ventas',
        school: 'Supermercados Carrefour',
        location: 'Campo de Criptana',
        others: 'Retail',
        startDate: 'Inicio',
        endDate: 'Fin (trabajo aquí)',
        totalDates: 'Total',
        description: 'Descripción del puesto',
        rating: 'Valoraciones',
        tag: 'Etiquetas',
      },
      {
        degree: 'Curso de atención al cliente y ventas',
        school: 'Supermercados Carrefour',
        location: 'Campo de Criptana',
        others: 'Retail',
        startDate: 'Inicio',
        endDate: 'Fin (trabajo aquí)',
        totalDates: 'Total',
        description: 'Descripción del puesto',
        rating: 'Valoraciones',
        tag: 'Etiquetas',
      },
    ],
    languages: [
      { name: 'Castellano', level: 'C2' },
      { name: 'Catalán', level: 'C2' },
      { name: 'Inglés', level: 'B1' },
    ],
  },
  reviews: [
    {
      name: 'Elena R.',
      jobTitle: 'Vendedor textil',
      rating: 4,
      pros: 'Teletrabajo. Gestión del horario.',
      cons: 'No enriquecerte de un gran equipo de trabajo',
      review:
        '100% recomendable, E*** y Oscar unos crack. Pero el mayor punto a destacar la comunidad. Poder hablar con gente que esta en una situación igual o parecida ayuda mucho a motivarse y seguir aprendiendo.',
      avatarUrl: imageP,
      timeAgo: 'Hace 3 semanas',
    },
  ],
  portfolio: [
    {
      name: 'Branding | Congreso Internacional de Género',
      description: 'Logotipo, imagen corporativa y voz y tono del proyecto',
      skills: 'Branding, género, logotipo, imagen corporativa',
      link: 'www.congresoenergiayeducacion.com',
      logoUrl: imageP2,
    },
  ],
  viewers: [
    {
      logoUrl:
        'https://popgroup.global/wp-content/uploads/2017/11/carrefour-logo-1.png',
      name: 'Supermercados Carrefour',
      timeAgo: 'Hace 11 horas',
    },
    {
      logoUrl:
        'https://popgroup.global/wp-content/uploads/2017/11/carrefour-logo-1.png',
      name: 'Mercadona',
      timeAgo: 'Hace 3 días',
    },
    {
      logoUrl:
        'https://popgroup.global/wp-content/uploads/2017/11/carrefour-logo-1.png',
      name: 'Supermercados Carrefour',
      timeAgo: 'Hace 2 semanas',
    }
  ],
};

const ProfilePage: React.FC = () => {
  return (
    <>
      <div className="bg-white pl-6">
        <Row gutter={[16, 16]}>
          <Col xs={24} md={24}>
            <Profile {...profileData} />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ProfilePage;
