import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Button, Input, Drawer } from "antd";
import { PlusOutlined, MenuOutlined } from "@ant-design/icons";
import Header from "../../components/pages/principalNav/HeaderOffers";
import SidebarOffers from "../../components/pages/offers/SidebarOffers";
import CardOffers from "../../components/pages/offers/CardOffers";
import IconSearch from "../../assets/icons/search.svg";
import '../../components/styles/pages/principalNav/PrincipalNav.css';

const Offers: React.FC = () => {
  const navigate = useNavigate(); // Initialize useNavigate
  const [loading, setLoading] = useState(true); // State to manage loading
  const [drawerVisible, setDrawerVisible] = useState(false); // State to control Drawer visibility
  const [selectedCard, setSelectedCard] = useState<number | null>(null); // State to manage selected card

  // Simulate data fetching with useEffect
  useEffect(() => {
    const fetchData = () => {
      // Simulate a data fetch
      setTimeout(() => {
        setLoading(false); // Set loading to false after data fetch
      }, 2000); // 2 seconds delay
    };

    fetchData();
  }, []);

  const cardProps = [
    {
      id: 1,
      title: "Reponedor",
      subtitle: "Reponedor de supermercado",
      location: "Alcazar de San Juan",
      activeDate: "Activa desde 20/3/2024",
      inscritos: 35,
      seleccionados: 10,
      descartados: 3,
    },
    {
      id: 2,
      title: "Mozo de almacén",
      subtitle: "Reponedor de supermercado",
      location: "Alcazar de San Juan",
      activeDate: "Activa desde 20/3/2024",
      inscritos: 35,
      seleccionados: 10,
      descartados: 3,
    },
    {
      id: 3,
      title: "Administrativo",
      subtitle: "Reponedor de supermercado",
      location: "Alcazar de San Juan",
      activeDate: "Activa desde 20/3/2024",
      inscritos: 35,
      seleccionados: 10,
      descartados: 3,
    },
    {
      id: 4,
      title: "Cajero en Criptana",
      subtitle: "Cajero de supermercado",
      location: "Campo de Criptana",
      activeDate: "Activa desde 20/3/2024",
      inscritos: 35,
      seleccionados: 10,
      descartados: 3,
    },
  ];

  const handleCardClick = (id: number) => {
    setSelectedCard(id); // Establece la tarjeta seleccionada
    // Redirige a la página de detalles de la oferta con el ID correspondiente
    navigate(`/offers/${id}`);
  };

  return (
    <>
      <Header />
      <div className="bg-white px-[24px] py-[48px] relative">
        <div className="flex flex-col md:flex-row">
          {/* Botón para abrir el Drawer solo en pantallas pequeñas */}
          <Button
            type="primary"
            className="md:hidden mb-3"
            onClick={() => setDrawerVisible(true)}
            icon={<MenuOutlined />}
          />

          {/* Sidebar visible solo en pantallas grandes */}
          <div style={{ width: "234px", backgroundColor: "white" }} className="hidden md:block p-0">
            <SidebarOffers />
          </div>

          {/* Drawer para pantallas pequeñas */}
          <Drawer
            title={null}
            placement="left"
            onClose={() => setDrawerVisible(false)}
            open={drawerVisible}
            drawerStyle={{ padding: 0 }}
            width={280}
            className="block md:hidden"
          >
            <SidebarOffers inDrawer />
          </Drawer>

          <div className="flex-1 pl-0 md:pl-[24px]">
            <Row gutter={[16, 16]}>
              <Col xl={12} md={12}>
                <div style={{ textAlign: "right", display: "flex", justifyContent: "left" }}>
                  <Input
                    prefix={<img src={IconSearch} width={24} height={24} />}
                    placeholder="Buscar oferta"
                    className="Input-Filter-Employment w-full md:w-[296px] custom-input-company px-2 rounded"
                  />
                  <Button className="bg-blue3 text-white w-[102px] ml-[18px] principal-nav-notify-button2 rounded-[4px]">
                    Buscar
                  </Button>
                </div>
              </Col>
              <Col xl={12} md={12} className="flex justify-end">
                <Button
                  className="principal-nav-notify-button md:w-[327px]"
                  onClick={() => navigate("/offers/createOffer")}
                >
                  <PlusOutlined />
                  Crear una nueva oferta de empleo
                </Button>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col xs={24} md={24}>
                <div className="pt-3">
                  <h2 className="text-heading-md font-bold mb-4">Listado de ofertas abiertas</h2>
                  {cardProps.slice(0, 3).map((props, index) => (
                    <div className="pb-[8px]" key={index} onClick={() => handleCardClick(props.id)}>
                      <CardOffers {...props} loading={loading} />
                    </div>
                  ))}
                  <h2 className="text-heading-md font-bold mb-4 mt-[48px]">Listado de guardadas (borrador)</h2>
                  <div className="pb-[8px]" onClick={() => handleCardClick(cardProps[3].id)}>
                    <CardOffers {...cardProps[3]} loading={loading} />
                  </div>
                  <h2 className="text-heading-md font-bold mb-4 mt-[48px]">Listado de finalizadas</h2>
                  {cardProps.slice(0, 3).map((props, index) => (
                    <div className="pb-[8px] " key={index} onClick={() => handleCardClick(props.id)}>
                      <CardOffers {...props} loading={loading} />
                    </div>
                  ))}
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

export default Offers;
