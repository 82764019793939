import React, { useState } from 'react';
import { Checkbox } from 'antd';

const CheckboxGroup = Checkbox.Group;

interface Option {
  label: string;
  value: string;
  color?: string;
  size?: string;
  padding?: string;
  fontWeight?: string | number;
}

interface AppProps {
  options: Option[];
  defaultCheckedList?: string[];
}

const LegendGroup: React.FC<AppProps> = ({ options = [], defaultCheckedList = [] }) => {
  const [checkedList, setCheckedList] = useState<string[]>(defaultCheckedList);

  const onChange = (list: string[]) => {
    setCheckedList(list);
  };

  return (
    <div className="grid grid-cols-2 gap-4">
      <CheckboxGroup
        options={options.map((option, index) => ({
          label: (
            <span
              key={option.value}
              style={{ color: option.color, fontSize: option.size, fontWeight: option.fontWeight, padding: option.padding }}
            >
              <style>
                {`
                .ant-checkbox .ant-checkbox-inner {
                  border: 2px solid #0778B1;
                }
                `}
              </style>
              {option.label}
            </span>
          ),
          value: option.value,
        }))}
        value={checkedList}
        onChange={onChange}
      />
    </div>
  );
};

export default LegendGroup;
