import { Action, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { RootState } from '../../store/store';
import axios from 'axios';
import {
  FETCH_GET_OFFER_SAVE_REQUEST,
  FETCH_GET_OFFER_SAVE_SUCCESS,
  FETCH_GET_OFFER_SAVE_FAILURE
} from '../../../constants/offers/GetOfferSave';

const API_URL = process.env.REACT_APP_API_BASE_URL;

export const GetOfferSaveReducer = (): ThunkAction<
  Promise<void>,
  RootState,
  unknown,
  Action<string>
> => async (dispatch: Dispatch, getState) => {
  dispatch({ type: FETCH_GET_OFFER_SAVE_REQUEST });
  const {
    rex_user
  } = getState().auth

  try {
    const response = await axios.get(
      `${API_URL}postulaciones-guardadas/${rex_user?.id}/getAllByUser`
    );
    console.log(response);
    
    const employmentData = response.data.data.map((offer: any) => ({
      id: offer.ofertas.id,
      empresa_id: offer.ofertas.empresa_id,
      cargo: offer.ofertas.cargo,
      descripcion: offer.ofertas.descripcion,
      ubi_poblacion: offer.ofertas.ubi_poblacion,
      sal_max: offer.ofertas.sal_max,
      jornada_laboral: offer.ofertas.jornada_laboral,
      updatedAt: offer.ofertas.updatedAt,
    }));

    dispatch({
      type: FETCH_GET_OFFER_SAVE_SUCCESS,
      payload: {
        data: employmentData,
        meta: response.data.meta
      }
    });
  } catch (error) {
    dispatch({ type: FETCH_GET_OFFER_SAVE_FAILURE, error });
    console.error('Error al obtener las ofertas', error);
  }
};
