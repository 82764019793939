import React from 'react';
import ReviewCard from '../myPortal/CardProfile'; // Ajusta la ruta de importación según sea necesario
import { Row, Col } from 'antd';
import imageP from '../../../assets/img/MyPortal/ImageP.svg';
import IconShare from '../../../assets/icons/shareP.svg';
import "tailwindcss/tailwind.css";


const handleAddClick = () => {
  console.log("Add button clicked");
  // Aquí puedes añadir la lógica para abrir un modal o redirigir a la página de agregar
};

const App: React.FC = () => {
  const reviewData = {
    name: 'Elena R.',
    jobTitle: 'Vendedor textil',
    rating: 4,
    pros: 'Teletrabajo. Gestión del horario.',
    cons: 'No enriquecerte de un gran equipo de trabajo',
    review: '100% recomendable, E*** y Oscar unos crack. Pero el mayor punto a destacar la comunidad. Poder hablar con gente que esta en una situación igual o parecida ayuda mucho a motivarse y seguir aprendiendo.',
    avatarUrl: imageP, // Reemplaza con la ruta actual a la imagen de avatar
    timeAgo: 'Hace 3 semanas',
  };

  return (
    <div className="pb-10">
      <h1 className="text-heading-md font-bold pb-3 flex items-center">
        Valoraciones
        <img
          src={IconShare}
          alt="Compartir"
          onClick={handleAddClick}
          className="inline-block text-sky-blue0 pl-3 cursor-pointer"
        />
      </h1>
      <Row gutter={24} align="top">
        {/* Columna para la tarjeta de reseña */}
        <Col xs={24} sm={24} lg={9}>
          <ReviewCard {...reviewData} />
        </Col>

        {/* Columna para la información del valorador */}
        <Col xs={24} sm={24} lg={12}>
          <div>
            <h2 className="text-heading-x1 font-bold pt-3 pb-3">Nombre del valorador</h2>
            <ul className="list-none space-y-2">
              <li className="text-body-md text-green22"><strong>Puesto:</strong> {reviewData.jobTitle}</li>
              <li className="text-body-md text-green22"><strong>Fecha:</strong> {reviewData.timeAgo}</li>
              <li className="text-body-md text-green22"><strong>Positivo:</strong> {reviewData.pros}</li>
              <li className="text-body-md text-green22"><strong>Negativo:</strong> {reviewData.cons}</li>
              <li className="text-body-md text-green22"><strong>Aptitudes #:</strong> 5</li> {/* Placeholder */}
              <li className="text-body-md text-green22"><strong>Reseña:</strong> {reviewData.review}</li>
              <li className="text-body-md text-green22"><strong>Valoración:</strong> 🌟 {reviewData.rating}</li>
            </ul>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default App;
