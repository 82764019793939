import React, { useState } from "react";
import { Modal, Button, Radio } from "antd";
import IconClosed from "../../../../assets/icons/IconClosed.svg";
import IconDelete from '../../../../assets/icons/IconDelete.svg';
import IconDownload from '../../../../assets/icons/IconDownload.svg';
import { PlusOutlined } from "@ant-design/icons";
import CustomRadio from '../ButtonRdio';
import ModalSaved from "./ModalSavedChanges";
import ModalDeleteCV from "./ModalDeleteCV";

interface CV {
  name: string;
  isDefault: boolean;
}

interface ModalCVSelectProps {
  visible: boolean;
  onClose: () => void;
}

const ModalCVSelect: React.FC<ModalCVSelectProps> = ({ visible, onClose }) => {
  const [cvs, setCVs] = useState<CV[]>([
    { name: "CvElenaRodriguez.pdf", isDefault: false },
    { name: "CvElenaRodriguez2.pdf", isDefault: false },
    { name: "CvElenaRodriguez3.pdf", isDefault: false },
  ]);

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [cvToDelete, setCVToDelete] = useState<CV | null>(null);
  const [isModalSavedVisible, setIsModalSavedVisible] = useState(false);
  const [isModalDeleteVisible, setIsModalDeleteVisible] = useState(false);

  const handleDefaultChange = (selectedCV: CV) => {
    setCVs(cvs.map(cv => ({ ...cv, isDefault: cv.name === selectedCV.name })));
    setIsSubmitDisabled(false);
  };

  const handleDeleteCV = () => {
    if (cvToDelete) {
      const updatedCVs = cvs.filter(cv => cv.name !== cvToDelete.name);
      setCVs(updatedCVs);
      setIsSubmitDisabled(false);
      setIsModalDeleteVisible(false); // Cerrar el modal después de eliminar
    }
  };

  const handleAddCVClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      // Simular la carga del archivo y agregarlo a la lista de CVs
      const newCV = { name: file.name, isDefault: false };
      setCVs([...cvs, newCV]);
      setIsModalSavedVisible(true); // Mostrar el modal después de subir el archivo
    }
  };

  const handleModalSavedClose = () => {
    setIsModalSavedVisible(false);
  };

  const handleModalDeleteClose = () => {
    setIsModalDeleteVisible(false);
  };

  const handleDeleteClick = (cv: CV) => {
    setCVToDelete(cv);
    setIsModalDeleteVisible(true);
  };

  return (
    <>
      <Modal
        open={visible && !isModalDeleteVisible && !isModalSavedVisible}
        onCancel={onClose}
        footer={null}
        centered
        closable={false}
        width={768}
        bodyStyle={{ borderRadius: "16px" }}
        style={{ borderRadius: "16px" }}
      >
        <img
          src={IconClosed}
          alt="Cerrar"
          onClick={onClose}
          style={{
            position: "absolute",
            top: "24px",
            right: "24px",
            cursor: "pointer",
            width: "24px",
            height: "24px",
          }}
        />

        <div className="text-center mb-[50px]">
          <h3 className="text-heading-md font-bold mt-[21px]">
            Mi Curriculum
          </h3>
        </div>

        <div className="mx-[9px] mb-[60px]">
          <div className="items-center pb-[12px] border-b border-[#E1E1E2]">
            <span className="text-body-md mr-[107px] font-semibold">Por defecto</span>
            <span className="text-body-md font-semibold">Curriculum en Word/PDF</span>
          </div>
          <ul className="">
            <Radio.Group value={cvs.find(cv => cv.isDefault)?.name} onChange={e => handleDefaultChange(cvs.find(cv => cv.name === e.target.value)!)}>
              {cvs.map((cv, index) => (
                <li key={index} className="flex justify-between items-center my-[18px]">
                  <div className="flex items-center w-[550px] mr-[100px]">
                    <CustomRadio value={cv.name}>
                      <span className="text-body-md font-normal ml-[128px]">{cv.name}</span>
                    </CustomRadio>
                  </div>
                  <div className="flex items-center">
                    <img
                      src={IconDownload}
                      alt="Descargar"
                      style={{
                        cursor: "pointer",
                        width: "24px",
                        height: "24px",
                        marginRight: "16px"
                      }}
                      onClick={() => console.log(`Descargar ${cv.name}`)} // Aquí puedes agregar la lógica de descarga
                    />
                    <img
                      src={IconDelete}
                      alt="Eliminar"
                      onClick={() => handleDeleteClick(cv)}
                      style={{
                        cursor: "pointer",
                        width: "24px",
                        height: "24px",
                      }}
                    />
                  </div>
                </li>
              ))}
            </Radio.Group>
          </ul>
          <div className="flex justify-center">
            <input
              type="file"
              style={{ display: "none" }}
              id="uploadCV"
              onChange={handleAddCVClick}
            />
            <Button
              className="mt-[10px] text-blue3 font-semibold border border-white w-[232px] h-[36px] principal-nav-notify-buttonS flex items-center justify-center"
              icon={<PlusOutlined />}
              onClick={() => document.getElementById('uploadCV')?.click()} // Desencadenar el clic en el input oculto
            >
              Añadir CV
            </Button>
          </div>
        </div>
      </Modal>

      <ModalSaved visible={isModalSavedVisible} onClose={handleModalSavedClose} />

      <ModalDeleteCV
        visible={isModalDeleteVisible}
        onClose={handleModalDeleteClose}
        skillName={null}
        // onConfirm={handleDeleteCV}
        // cvName={cvToDelete?.name}
      />
    </>
  );
};

export default ModalCVSelect;
