import React from 'react';
import { Avatar, Rate, Tag, Select, Checkbox, Button } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { Radar, RadarChart, PolarGrid, Legend, PolarAngleAxis, PolarRadiusAxis, ResponsiveContainer } from 'recharts';
import { useNavigate } from 'react-router-dom'; // Importar useNavigate
import '../../../styles/pages/offers/OffersProfileInfo.css';
import StyledCheckbox from '../../../checkbox/CheckboxProps';
import CustomLegend from './Legend';
import RatingBlue from '../../../rating/RatingBlue'

const { Option } = Select;

const data = [
  { subject: 'Colaborativo', A: 7, B: 6, fullMark: 10 },
  { subject: 'Innovador', A: 6, B: 5, fullMark: 10 },
  { subject: 'Detallista', A: 5, B: 6, fullMark: 10 },
  { subject: 'Proactivo', A: 4, B: 4, fullMark: 10 },
  { subject: 'Adaptable', A: 6, B: 5, fullMark: 10 },
  { subject: 'Energético', A: 3, B: 4, fullMark: 10 },
  { subject: 'Empático', A: 2, B: 3, fullMark: 10 },
  { subject: 'Creativo', A: 7, B: 8, fullMark: 10 },
  { subject: 'Analítico', A: 5, B: 6, fullMark: 10 },
];

const Example = () => (
  <div style={{ position: 'absolute', top: '-90px', left: '-80px', width: '100%', height: '100%', zIndex: 2 }}>
    <ResponsiveContainer width="140%" height={400}>
      <RadarChart cx="50%" cy="50%" outerRadius="70%" data={data}>
        <PolarGrid />
        <PolarAngleAxis
          dataKey="subject"
          tick={{ fontSize: 11, fill: '#000', fontWeight: 'Bold' }}
        />
        <PolarRadiusAxis angle={30} domain={[0, 10]} />
        <Radar name="Perfil del Candidato" dataKey="A" stroke="#006497" fill="transparent" />
        <Radar name="Perfil de la oferta" dataKey="B" stroke="#FDBCB4" fill="transparent" />
        <Legend content={<CustomLegend />} />
      </RadarChart>
    </ResponsiveContainer>
  </div>
);


const OffersProfileInfo = ({
  offerId, // Nuevo prop para el ID de la oferta
  candidateId, // Nuevo prop para el ID del candidato
  avatarUrl,
  name,
  role,
  location,
  rating,
  reviewsLink,
  experiences,
  education,
  tags,
  status,
  cvLink,
  killerQuestions,
  aptitudes,
  language,
  onSelectAnswer
}: any) => {
  const navigate = useNavigate(); // Inicializar useNavigate

  const handleNameClick = () => {
    if (offerId && candidateId) {
      navigate(`/offers/${offerId}/candidate/${candidateId}`);
    } else {
      console.error("ID del candidato o de la oferta no definidos");
    }
  };

  return (
    <div onClick={handleNameClick} style={{ position: 'relative', zIndex: 1 }} className=" mx-auto p-1 -ml-5 -mt-8 bg-gray-100 rounded-lg cursor-pointer">
      <div className="flex items-center ml-[-8px] cursor-pointer">
        <StyledCheckbox value={0} children={undefined} /> {/* Usar StyledCheckbox */}
        <Avatar size={64} src={avatarUrl} shape="square" className="!rounded-none bg-white" />
        <div className="ml-4">
          <h2 className="text-[18px] font-medium text-[#1A1A1A] opacity-70">
            {name}
          </h2>
          <p className="text-[16px] font-bold text-[#1A1A1A] opacity-70">{role}</p>
          <p className="text-[12px] font-medium text-[#1A1A1A] opacity-70">{location}</p>
        </div>
        <p className="ml-auto text-[12px] font-medium -mt-10 text-[#1A1A1A] opacity-70">Cualitativos</p>
      </div>

      {/* Valoraciones */}
      <div className="flex items-center mt-4">
        <h3 className="font-bold text-[12px]">Valoraciones</h3>
        <div className="flex items-center ml-4 space-x-2">
          <RatingBlue totalStars={5} filledStars={rating} /> {/* Usar RatingBlue con el prop `rating` */}
          <Tag className="text-[12px] font-medium text-[#1A1A1A] opacity-70 border border-black rounded-full">
            {reviewsLink}
          </Tag>
        </div>
      </div>

      {/* Experiencia */}
      <div className="mt-4 flex">
        <h3 className="font-bold mr-4 text-[12px]">Experiencia</h3>
        <div className="text-[14px] font-medium text-[#1A1A1A] opacity-70">
          {experiences && experiences.map((exp: any, index: number) => (
            <p key={index}>
              {exp.split(' ').map((word: any, idx: any) => {
                if (word === 'charcutería') {
                  return <span key={idx} className="font-bold text-black">{word}</span>;
                } else if (word.includes('meses)')) {
                  return <span key={idx} className="italic">{word}</span>;
                } else if (word === 'C1' || word === 'B2') {
                  return <span key={idx} className="font-bold">{word}</span>;
                } else {
                  return <span key={idx}>{word} </span>;
                }
              })}
            </p>
          ))}
        </div>
      </div>

      {/* Tags (Idiomas y otros) */}
      <div className="mt-2 ml-20 flex flex-wrap -gap-2">
        {tags && tags.map((tag: any, index: number) => (
          <Tag key={index} className="text-[12px] font-medium text-[#1A1A1A] opacity-70 border border-black rounded-full">
            {tag}
          </Tag>
        ))}
        <span className="font-bold text-[12px] mr-2">Idiomas</span>
        {language && language.map((lan: any, index: number) => (
          <Tag key={index} className="ml-1 text-[12px] font-medium text-[#1A1A1A] opacity-70 border border-black rounded-full">
            {lan}
          </Tag>
        ))}
      </div>

      {/* Educación */}
      <div className="mt-2 flex">
        <h3 className="font-bold mr-[21px] text-[12px]">Educación</h3>
        <div className="text-[14px] font-medium text-[#1A1A1A] opacity-70">
          {education && education.map((edu: any, index: number) => (
            <p key={index}>{edu}</p>
          ))}
        </div>
      </div>

      {/* Nueva Información (Búsqueda activa, etc.) */}
      <div className="-mt-2 ml-[78px]">
        <span className="font-bold text-[12px] text-[#1A1A1A] opacity-80">{status}</span>
        <Button type="link" className="font-bold text-[12px]" style={{ color: '#00476D' }} href={cvLink}>
          Ver CV en pdf
        </Button>
      </div>

      {/* Aptitudes y Killer Questions */}
      <div className="flex mt-1 items-center">
        <div className="flex-1">
          <span className="font-bold text-[12px]">Aptitudes</span>
          <span className="font-bold text-[12px] ml-5 mr-2">{killerQuestions}</span>
          <Select
            defaultValue="Seleccionar Respuesta"
            className="text-[12px] font-medium !text-[#00476D] border border-[#00476D] rounded mr-2 h-7"
            onChange={onSelectAnswer}
          >
            <Option value="1">Respuesta 1</Option>
            <Option value="2">Respuesta 2</Option>
            <Option value="3">Respuesta 3</Option>
          </Select>
          <span className="font-bold text-[12px]">Aptitudes:</span>
          {aptitudes && aptitudes.map((apt: any, index: number) => (
            <Tag key={index} className="ml-2 text-[12px] font-medium text-[#1A1A1A] opacity-70 border border-black rounded-full">
              {apt}
            </Tag>
          ))}
        </div>
      </div>
    </div>
  );
};

const OffersProfile = (props: any) => {
  return (
    <div className=" p-6 bg-gray-100 rounded-lg relative ">
      <div className="flex flex-wrap -mx-3 relative">
        <div className="w-full md:w-3/4 px-3 relative">
          <OffersProfileInfo {...props} />
        </div>
        <div className="w-full md:w-1/4 px-3 mt-2 md:mt-0 relative">
          <Example />
        </div>
      </div>
    </div>
  );
};

export default OffersProfile;
