import React from 'react';

const CardUserExample: React.FC = () => {
    return (
        <div style={{ padding: 24, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <h1>aqui contenido4 </h1>
        </div>
    );
};

export default CardUserExample;