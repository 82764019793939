import React from 'react';
import { Card, Row, Col, Avatar, Typography, Space } from 'antd';
import check from '../../../assets/img/MyPortal/CheckP.svg';
import RatingBlue from '../../rating/RatingBlue'; // Cambio realizado aquí

const { Text } = Typography;

interface ReviewCardProps {
  name: string;
  jobTitle: string;
  rating: number;
  pros: string;
  cons: string;
  review: string;
  avatarUrl: string;
  timeAgo: string;
}

const ReviewCard: React.FC<ReviewCardProps> = ({ name, jobTitle, rating, pros, cons, review, avatarUrl, timeAgo }) => {
  return (
    <Card className="rounded-none shadow-xl" style={{ maxWidth: '335px', marginRight: '20px' }}>
      <Row gutter={[16, 16]}>
        <Col span={6} className="flex justify-center items-center">
          <Avatar src={avatarUrl} size={60} className="rounded-full" />
        </Col>
        <Col span={18}>
          <Space direction="vertical" size="small" style={{ rowGap:'3px' }}>
            <div className="flex items-center">
              <Text className="font-bold text-body-md">{name}</Text>
              <img src={check} alt="Check" className="ml-1" />
            </div>
            <Text className="text-green42 font-medium text-body-sm">{jobTitle}</Text>
            <RatingBlue 
              filledStars={rating} // Cambio realizado aquí
              showRatingValue={false} 
              filledStarSize={{ width: '20px', height: '20px', marginRight: '2px', marginTop: '2px' }}
              emptyStarSize={{ width: '24px', height: '24px', marginLeft: '-2px' }}
             
            />
          </Space>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col span={6}>
          <Text className="font-bold text-greenv text-body-sm">Lo mejor</Text>
        </Col>
        <Col span={18}>
          <Text className="font-bold text-green42 text-body-sm">{pros}</Text>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col span={6}>
          <Text className="font-bold text-orangeo text-body-sm">Lo peor</Text>
        </Col>
        <Col span={18}>
          <Text className="font-bold text-green42 text-body-sm">{cons}</Text>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col span={24}>
          <Text className="font-medium text-green42 text-body-sm">{review}</Text>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col span={24} className="flex justify-end">
          <Text className="text-grays font-medium text-caption">{timeAgo}</Text>
        </Col>
      </Row>
    </Card>
  );
};

export default ReviewCard;
