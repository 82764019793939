// IconButton.tsx
import React from "react";

interface IconButtonProps {
  icon: string;
  selected: boolean;
  onClick: () => void;
  altText: string;
}

const IconButton: React.FC<IconButtonProps> = ({ icon, selected, onClick, altText }) => {
  return (
    <div
      className={`p-1 cursor-pointer ${selected ? "border-2 border-blue3 rounded-[4px]" : ""}`}
      onClick={onClick}
    >
      <img src={icon} alt={altText} className="w-[30px] h-[30px]" />
    </div>
  );
};

export default IconButton;
