import React, { useState, useEffect } from "react";
import { Modal, Button, Input } from "antd";
import IconClosed from "../../../../assets/icons/IconClosed.svg";
import ModalSaved from '../../myPortal/Modals/ModalSavedChanges';

interface ModalEditAboutMeProps {
  visible: boolean;
  onClose: () => void;
}

const ModalEditAboutMe: React.FC<ModalEditAboutMeProps> = ({ visible, onClose }) => {
  const [comment, setComment] = useState(""); // Define the comment state
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [isSecondModalVisible, setIsSecondModalVisible] = useState(false);
  const handleCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => setComment(e.target.value); // Implement handleCommentChange

  useEffect(() => {
    const isValid = comment.length > 0; // Include comment validation
    setIsSubmitDisabled(!isValid);
  }, [comment]); // Add comment to the dependency array

  const handleSendValuation = () => {
    if (!isSubmitDisabled) {
      onClose();
      setIsSecondModalVisible(true);
    }
  };

  return (
    <>
      <Modal
        open={visible}
        onCancel={onClose}
        footer={null}
        centered
        closable={false}
        width={677}
        bodyStyle={{ borderRadius: "12px" }}
        style={{ borderRadius: "12px", border: "1px solid #E1E1E2", marginTop: '15px', marginBottom: '15px' }}
      >
        <img
          src={IconClosed}
          alt="Cerrar"
          onClick={onClose}
          style={{
            position: "absolute",
            top: "34px",
            right: "34px",
            cursor: "pointer",
            width: "24px",
            height: "24px",
          }}
        />

        <div className="text-center mx-[86px] mb-[36px] mt-[72px]">
          <h3 className="mt-[16px] mx-[44px] text-heading-md font-bold">
            Editar Sobre mí
          </h3>
          <p className="font-medium px-[55px] text-body-sm mt-[10px]">
            Por favor, a continuación edita tu información
          </p>
        </div>

        <div className="mx-[86px] mb-[32px] mt-[40px]">
          <span className="text-body-md text-[#757575] font-normal">
            Sobre mi
          </span>
          <Input.TextArea
            rows={5}
            value={comment} // Bind the state to the Input.TextArea
            onChange={handleCommentChange} // Handle changes
            className="w-full 
                border
                my-[8px]
                border-[#D9D9D9] 
                placeholder:text-green32 
                focus:placeholder:text-grays 
                hover:placeholder:text-black 
                hover:bg-gray3 
                hover:border-2 
                hover:border-[#D9D9D9]
                hover:text-black 
                focus:border-4 
                focus:border-[#91c3fd] 
                focus:text-[#757575]
                rounded-[8px] 
                transition-all 
                duration-200 
                text-[#757575]
                font-normal
                text-body-md"
            style={{ height: 80 }}
          />
          <span className="text-body-md font-normal text-[#757575]">
            Máximo 400 carácteres
          </span>

          <div className="flex justify-center mt-[40px]">
            <Button
              onClick={onClose}
              className="principal-nav-notify-buttonG w-[118px] h-[44px]"
              style={{ marginRight: "8px", borderRadius: "4px" }}
            >
              Cancelar
            </Button>
            <Button
              disabled={isSubmitDisabled}
              onClick={handleSendValuation}
              className={`w-[181px] h-[44px] ml-[18px] rounded-[4px] ${isSubmitDisabled ? 'bg-[#F4F4F5] text-[#757575] font-semibold text-body-md cursor-not-allowed' : 'bg-blue3 principal-nav-notify-button2 text-white cursor-pointer'}`}
            >
             Guardar cambios
            </Button>
          </div>
        </div>
      </Modal>

      {/* Modal secundario */}
      <ModalSaved 
        visible={isSecondModalVisible} 
        onClose={() => setIsSecondModalVisible(false)}
      />
    </>
  );
};

export default ModalEditAboutMe;
