import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Input, Button, Select, Row, Col, Drawer, Skeleton, Card } from 'antd';
import IconSearch from '../../../assets/icons/search.svg';
import alerts from '../../../assets/icons/alerts.svg';
import '../../../styles/pages/employment/Employment.css';
import '../../../styles/pages/employment/FiltersEmployment.css';
import FilterComponent from "../../../components/pages/employment/searchResult/SidebarFilter";
import './Company.css';
import CardEmpresa from '../../../components/cards/CardCompany';
import Header from '../../../components/pages/principalNav/PrincipalNav';
import { MenuOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from 'react-redux';
import { GetCompanyReducer } from '../../../redux/actions/pages/company/Company';
import { RootState, AppDispatch } from '../../../redux/store/store';
import InfiniteScroll from 'react-infinite-scroll-component';

const { Option } = Select;

const Company: React.FC = () => {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1); // Página actual
  const [loadingMoreData, setLoadingMoreData] = useState(false); // Estado de carga para los datos adicionales
  const [hasMore, setHasMore] = useState(true); // Controla si hay más datos para cargar
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { rex_companies, rex_error, rex_total, rex_limit } = useSelector((state: RootState) => state.company);

  // Nuevo efecto para limpiar el estado cuando el componente se desmonta
  useEffect(() => {
    return () => {
      setPage(1); // Reiniciar la página al desmontar el componente
      setHasMore(true); // Permitir que los datos se carguen de nuevo cuando se vuelva a la vista
    };
  }, []);

  // Efecto para cargar los datos cuando se monta el componente o cuando se vuelve a la vista
  useEffect(() => {
    if (rex_companies.length === 0 || page === 1) { // Si no hay datos o estamos en la primera página
      setLoading(true);
      dispatch(GetCompanyReducer(1, rex_limit)).then(() => {
        setLoading(false);
      });
    }
  }, [dispatch, rex_limit, page]);

  const handleCardClick = (id: string) => {
    navigate(`/companyDetail/${id}`);
  };

  const toggleDrawer = () => {
    setDrawerVisible(!drawerVisible);
  };

  const fetchMoreData = () => {
    // Evitar cargar más si estamos en medio de otra solicitud o si no hay más datos
    if (loadingMoreData || !hasMore) {
      return;
    }

    setLoadingMoreData(true);

    const nextPage = page + 1;
    const totalPages = Math.ceil(rex_total / rex_limit);

    // Simular una carga con un retardo de 1 segundo
    setTimeout(() => {
      dispatch(GetCompanyReducer(nextPage, rex_limit))
        .then(() => {
          setPage(nextPage); // Actualizamos la página cuando los datos ya están cargados
          setLoadingMoreData(false); // Liberamos el estado de carga
          if (nextPage >= totalPages) {
            setHasMore(false); // No hay más datos por cargar
          }
        })
        .catch(() => {
          setLoadingMoreData(false); // Aseguramos que se libere el estado de carga en caso de error
        });
    }, 1000); // Retardo de 1 segundo para simular la carga
  };

  return (
    <>
      <Header />
      <div className="company-container ml-[24px] mr-[24px]">
        <div className="bg-pink-box"></div>

        <Drawer
          title="Filtrar"
          placement="left"
          onClose={toggleDrawer}
          open={drawerVisible}
          width={300}
          className="md:hidden"
        >
          <FilterComponent />
        </Drawer>

        <Row gutter={[16, 16]}>
          <Col xl={12} md={12}>
            <span className="text-2xl font-bold company-text text-center md:text-left mb-4 md:mb-0">
              Sigue a las empresas que más te<br /> interesen del sector
            </span>
          </Col>
          <Col xl={12} md={12}>
            <div style={{ textAlign: 'right', display: 'flex', justifyContent: 'right', flexDirection: 'column' }}>
              <div style={{ display: 'flex', justifyContent: 'right' }}>
                <Input
                  prefix={<img src={IconSearch} width={24} height={24} />}
                  placeholder="Buscar empleo de..."
                  className="Input-Filter-Employment w-full mr-[8px] md:w-[417px] custom-input-company px-2 rounded"
                />
                <div>
                  <Select
                    placeholder="Sector (Opcional)"
                    className={`Select-Filter-Employment mr-[8px] w-[225px]`}
                    style={{ textAlign: "left" }}
                  >
                    <Option value="sector1">Sector 1</Option>
                    <Option value="sector2">Sector 2</Option>
                    <Option value="sector3">Sector 3</Option>
                  </Select>
                </div>
                <Button
                  className="Button-Filter-Employment h-[44px] px-4 rounded w-[102px] md:w-auto mt-4 md:mt-0 ml-[8px]"
                >
                  Buscar
                </Button>
              </div>
              <Button
                className="Button-Alerts h-[36px] px-4 rounded w-[200px] mt-4 ml-[69%] text-[14px] font-semibold text-[#006497]"
                style={{ borderColor: '#006497', borderRadius: '4px', borderWidth: '1px', borderStyle: 'solid' }}
                icon={<img src={alerts} width={20} height={20} />}
                onClick={() => navigate('/followed-companies')}
              >
                Ver Empresas que sigo
              </Button>
            </div>
          </Col>
        </Row>

        <p className="text-gray-700 font-bold text-lg mb-8 text-center md:text-left mt-[55px]">
          Busca o filtra entre empresas reclutadoras por "valoraciones", "sector", "palabras clave"...
        </p>

        <Row gutter={[16, 16]}>
          <Col xl={5} md={6} className="hidden md:block">
            <div style={{ width: "234px", backgroundColor: "white" }}>
              <FilterComponent />
            </div>
          </Col>

          <Col xl={19} md={18} sm={24}>
            <Button
              className="md:hidden mb-4"
              type="primary"
              icon={<MenuOutlined />}
              onClick={toggleDrawer}
            />
            {loading ? (
              Array.from({ length: 4 }).map((_, index) => (
                <Card key={index} className="mb-4" style={{ width: "100%", borderRadius: '4px' }}>
                  <Skeleton avatar paragraph={{ rows: 3 }} active />
                </Card>
              ))
            ) : (
              <InfiniteScroll
                dataLength={rex_companies.length}  // Número de empresas cargadas
                next={fetchMoreData}  // Función para cargar más datos
                hasMore={hasMore}  // Indica si hay más datos por cargar
                loader={
                  <div style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
                    <Skeleton avatar paragraph={{ rows: 3 }} active /> {/* El loader centrado */}
                  </div>
                }
                endMessage={
                  <p style={{ textAlign: 'center' }}>
                    <b>Ya no hay más datos</b>
                  </p>
                }
                scrollThreshold={0.95}  // Solo carga más datos cuando estás en el 95% de scroll
              >
                {rex_companies
                  .filter((company, index, self) => self.findIndex(c => c.id === company.id) === index) // Eliminar duplicados
                  .map((company, index) => (
                    <React.Fragment key={company.id}>
                      {/* Aquí agregamos el texto de "Datos de la página X" */}
                      {index % rex_limit === 0 && (
                        <p style={{ color: 'red', textAlign: 'center', fontWeight: 'bold' }}>
                          Datos de la página {Math.floor(index / rex_limit) + 1}
                        </p>
                      )}
                      <CardEmpresa
                        title={company.empresa}
                        description="Descripción desde API"
                        location="Ubicación desde API"
                        link="https://carrefour.es"
                        avatarUrl="https://popgroup.global/wp-content/uploads/2017/11/carrefour-logo-1.png"
                        reviews={15}
                        activeOffers={120}
                        followers={15}
                        workers={26}
                        categories={['Categoría desde API']}
                        onClick={() => handleCardClick(company.id)}
                        className="mb-4"
                      />
                    </React.Fragment>
                  ))}
              </InfiniteScroll>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Company;
