import React, { useState } from "react";
import { Row, Col } from "antd";
import "tailwindcss/tailwind.css";
import IconEdit from '../../../assets/icons/EditP.svg';
import IconMas from '../../../assets/icons/plusP.svg';
import ModalEditPerfil from '../myPortal/Modals/ModalEditProfileInformation';
import ModalEditAboutMe from '../myPortal/Modals/ModalEditAboutMe';
import ModalEditSkills from "./Modals/ModalEditSkills";
import ModalEditWork from "./Modals/ModalEditWorkExperience";
import ModalEditEducation from './Modals/ModalEditEducation';
import ModalEditLanguages from "./Modals/ModalEditLanguages";

interface ProfileInfoProps {
  name: string;
  lastNameP: string;
  lastNameS: string;
  aboutMe: string;
  skills: string[];
  experience: {
    title: string;
    company: string;
    other: string;
    location: string;
    startDate: string;
    endDate: string;
    totalDate: string;
    description: string;
    ratings: string;
    tags: string;
  }[];
  education: {
    degree: string;
    school: string;
    others: string;
    location: string;
    startDate: string;
    endDate: string;
    totalDates: string;
    description: string;
    rating: string;
    tag: string;
  }[];
  languages: { name: string; level: string }[];
}

const ProfileInfo: React.FC<ProfileInfoProps> = ({
  name,
  lastNameP,
  lastNameS,
  aboutMe,
  skills,
  experience,
  education,
  languages,
}) => {
  // Estados para controlar la visibilidad de los modales específicos
  const [isModalPerfilVisible, setIsModalPerfilVisible] = useState(false);
  const [isModalAboutMeVisible, setIsModalAboutMeVisible] = useState(false);
  const [isModalSkillsVisible, setIsModalSkillsVisible] = useState(false);
  const [isModalWorkVisible, setIsModalWorkVisible] = useState(false);
  const [isModalEducationVisible, setIsModalEducationVisible] = useState(false);
  const [isModalLanguagesVisible, setIsModalLanguagesVisible] = useState(false);

  const [showMoreExperience, setShowMoreExperience] = useState(false);
  const [showMoreEducation, setShowMoreEducation] = useState(false);
  const maxItems = 3;

  const visibleExperience = showMoreExperience ? experience : experience.slice(0, maxItems);
  const visibleEducation = showMoreEducation ? education : education.slice(0, maxItems);

  const handleEditPerfilClick = () => {
    setIsModalPerfilVisible(true);
  };

  const handleEditAboutMeClick = () => {
    setIsModalAboutMeVisible(true);
  };

  const handleEditSkillsClick = () => {
    setIsModalSkillsVisible(true);
  };

  const handleEditWorkClick = () => {
    setIsModalWorkVisible(true);
  };

  const handleEditEducationClick = () => {
    setIsModalEducationVisible(true);
  };

  const handleEditLanguagesClick = () => {
    setIsModalLanguagesVisible(true);
  };

  const handleModalClose = () => {
    setIsModalPerfilVisible(false);
    setIsModalAboutMeVisible(false);
    setIsModalSkillsVisible(false);
    setIsModalWorkVisible(false);
    setIsModalEducationVisible(false);
    setIsModalLanguagesVisible(false);
  };

  return (
    <div>
      <div className="pb-10">
        <h1 className="text-heading-md font-bold">Perfil</h1>
      </div>
      <div className="pb-10">
        <h1 className="text-heading-x1 font-bold pb-3">
          Información de perfil
          <img
            src={IconEdit}
            alt="Editar"
            onClick={handleEditPerfilClick}  // Solo abre el modal de perfil
            className="inline-block text-sky-blue0 pl-3 cursor-pointer"
          />
        </h1>
        <h1 className="text-body-md font-medium text-green22">{name}</h1>
        <h1 className="text-body-md font-medium text-green22">{lastNameP}</h1>
        <h1 className="text-body-md font-medium text-green22">{lastNameS}</h1>
      </div>

      <div className="pb-10">
        <h1 className="text-heading-x1 font-bold pb-3">
          Sobre mí
          <img
            src={IconEdit}
            alt="Editar"
            onClick={handleEditAboutMeClick}  // Solo abre el modal de "Sobre mí"
            className="inline-block text-sky-blue0 pl-3 cursor-pointer"
          />
        </h1>
        <h1 className="text-body-md font-medium text-green22 text-justify">{aboutMe}</h1>
      </div>

      <div className="pb-10">
        <h1 className="text-heading-x1 font-bold pb-3">
          Habilidades
          <img
            src={IconEdit}
            alt="Editar"
            onClick={handleEditSkillsClick} // Abre el modal de habilidades
            className="inline-block text-sky-blue0 pl-3 cursor-pointer"
          />
        </h1>
        <h1 className="text-body-md font-bold">{skills.join(", ")}</h1>
      </div>

      <div className="pb-10">
      <h1 className="text-heading-x1 font-bold pb-3">
        Experiencia laboral
        <img
          src={IconMas}
          alt="Añadir"
          // onClick={() => handleAddClick('Experiencia laboral')}
          className="inline-block text-sky-blue0 pl-3 cursor-pointer"
        />
      </h1>
      <Row gutter={[16, 16]}>
        {visibleExperience.map((job, index) => (
          <Col key={index} xs={24} sm={24} lg={8}>
            <h1 className="text-body-md font-bold pb-1 flex items-center">
              {job.title}
              <img
                src={IconEdit}
                alt="Editar"
                onClick={handleEditWorkClick} // Abre el modal de experiencia laboral
                className="inline-block text-sky-blue0 pl-3 cursor-pointer"
              />
            </h1>
            <h1 className="text-body-md font-medium text-green22 pb-1">
              {job.company} | {job.other}
            </h1>
            <h1 className="text-body-md font-medium text-green22 pb-1">
              {job.location}
            </h1>
            <h1 className="text-body-md font-medium text-green22 pb-1">
              {job.startDate} | {job.endDate} | {job.totalDate}
            </h1>
            <h1 className="text-body-md font-medium text-green22 pb-1">
              {job.description}
            </h1>
            <h1 className="text-body-md font-medium text-green22 pb-1">
              {job.ratings}
            </h1>
            <h1 className="text-body-md font-medium text-green22 pb-1">
              {job.tags}
            </h1>
          </Col>
        ))}
      </Row>
      <div className="text-center">
          {experience.length > maxItems && (
            <button
              onClick={() => setShowMoreExperience(!showMoreExperience)}
              className="text-[#006497] text-[14px] font-semibold underline"
            >
              {showMoreExperience ? 'Ver menos' : 'Ver más'}
            </button>
          )}
        </div>
    </div>

      <div className="pb-10">
        <h1 className="text-heading-x1 font-bold pb-3">
          Educación
          <img
            src={IconMas}
            alt="Añadir"
            // onClick={() => handleAddClick('Educación')}
            className="inline-block text-sky-blue0 pl-3 cursor-pointer"
          />
        </h1>
        <Row gutter={[16, 16]}>
          {visibleEducation.map((edu, index) => (
            <Col key={index} xs={24} sm={24} lg={8}>
              <h1 className="text-body-md font-bold pb-1">{edu.degree}
              <img
                src={IconEdit}
                alt="Editar"
                onClick={handleEditWorkClick} // Abre el modal de experiencia laboral
                className="inline-block text-sky-blue0 pl-3 cursor-pointer"
              />
              </h1>
              <h1 className="text-body-md font-medium text-green22 pb-1">
                {edu.school} | {edu.others}
              </h1>
              <h1 className="text-body-md font-medium text-green22 pb-1">
                {edu.location}
              </h1>
              <h1 className="text-body-md font-medium text-green22 pb-1">
                {edu.startDate} | {edu.endDate} | {edu.totalDates}
              </h1>
              <h1 className="text-body-md font-medium text-green22 pb-1">
                {edu.description}
              </h1>
              <h1 className="text-body-md font-medium text-green22 pb-1">
                {edu.rating}
              </h1>
              <h1 className="text-body-md font-medium text-green22 pb-1">
                {edu.tag}
              </h1>
            </Col>
          ))}
        </Row>
        <div className="text-center">
          {education.length > maxItems && (
            <button
              onClick={() => setShowMoreEducation(!showMoreEducation)}
              className="text-[#006497] text-[14px] font-semibold underline"
            >
              {showMoreEducation ? 'Ver menos' : 'Ver más'}
            </button>
          )}
        </div>
      </div>

      <div className="pb-10">
        <h1 className="text-heading-x1 font-bold pb-3">
          Idiomas
          <img
            src={IconEdit}
            alt="Editar"
            onClick={handleEditLanguagesClick} // Abre el modal de idiomas
            className="inline-block text-sky-blue0 pl-3 cursor-pointer"
          />
          <img
            src={IconMas}
            alt="Añadir"
            // onClick={() => handleAddClick('Idiomas')}
            className="inline-block text-sky-blue0 pl-3 cursor-pointer"
          />
        </h1>
        <Row gutter={[16, 5]}>
          {languages.map((lang, index) => (
            <Col key={index} span={16}>
              <h1 className="text-body-md font-medium text-green22 pr-1 inline-block">
                {lang.name}
              </h1>
              <span className="text-body-md font-bold text-green22">
                {lang.level}
              </span>
            </Col>
          ))}
        </Row>
      </div>

      {/* Modal de myportal */}
      <ModalEditPerfil
        visible={isModalPerfilVisible}
        onClose={handleModalClose}
        context="myPortal"
      />

      <ModalEditAboutMe
        visible={isModalAboutMeVisible}
        onClose={handleModalClose}
      />

      <ModalEditSkills
        visible={isModalSkillsVisible}
        onClose={handleModalClose}
        skills={skills}
      />

      <ModalEditWork
        visible={isModalWorkVisible}
        onClose={handleModalClose}
      // experience={experience} 
      />

      <ModalEditEducation
        visible={isModalEducationVisible}
        onClose={handleModalClose}
        // education={education}
      />

      <ModalEditLanguages
        visible={isModalLanguagesVisible}
        onClose={handleModalClose}
        languages={languages}
      />
    </div>
  );
};

export default ProfileInfo;
