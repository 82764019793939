import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Carousel, Button } from 'antd';
import Header from "../../../components/pages/principalNav/HeaderOffers";
import CandidateInfo from "../../../components/pages/offers/offersProfile/CandidateInfo";
import LaboralExp from "../../../components/pages/offers/offersProfile/LaboralExp";
import Portfolio from "../../../components/pages/offers/offersProfile/Portfolio";
import ReviewCard from '../../../components/pages/myPortal/CardProfile';
import RatingBlue from '../../../components/rating/RatingBlue'; // Cambio realizado aquí
import Avatar1 from "../../../assets/img/offers/Avatar.png";
import icon from "../../../assets/img/offers/ArrowLeft.svg";

const CandidateInformation: React.FC = () => {
  const { candidateId } = useParams<{ candidateId: string }>();

  const [isProfileUnlocked, setIsProfileUnlocked] = useState(false);

  const handleUnlockProfile = () => {
    setIsProfileUnlocked(true);
  };

  const candidateData = {
    1: {
      imageUrl: Avatar1,
      nombre: "Elena Rodriguez",
      puesto: "Reponedor",
      ubicacion: "Alcazar de San Juan",
      habilidades: ["Office", "Informática", "Dependiente con experiencia"],
      descripcion: "Soy un entusiasta del mundo digital con una pasión por transformar datos en decisiones estratégicas. Con más de 5 años de experiencia en análisis de datos y machine learning...",
      radarData: [
        { subject: 'Comunicación', A: 120, B: 110 },
        { subject: 'Trabajo en equipo', A: 98, B: 130 },
        { subject: 'Liderazgo', A: 86, B: 130 },
        { subject: 'Creatividad', A: 99, B: 100 },
        { subject: 'Adaptabilidad', A: 85, B: 90 },
      ],
      experiences: [
        {
          title: "Cajero de supermercado",
          company: "Supermercados Carrefour",
          location: "Retail",
          startDate: "Enero 2020",
          endDate: "Mayo 2022",
          totalDuration: "2 años y 4 meses",
          description: "Atención al cliente, manejo de caja registradora y reposición de productos.",
          ratings: "4.5/5",
          tags: ["Atención al cliente", "Caja registradora"],
        },
        {
          title: "Ayudante de Charcuteria",
          company: "Supermercados Carrefour",
          location: "Retail",
          startDate: "Enero 2020",
          endDate: "Mayo 2022",
          totalDuration: "2 años y 4 meses",
          description: "Atención al cliente, manejo de caja registradora y reposición de productos.",
          ratings: "4.5/5",
          tags: ["Atención al cliente", "Caja registradora"],
        },
      ],
      education: [
        {
          title: "Bachillerato de arte",
          institution: "Instituto de Educación Secundaria",
          location: "Alcazar de San Juan",
          startDate: "Septiembre 2016",
          endDate: "Junio 2018",
          totalDuration: "2 años",
          description: "Estudios enfocados en historia del arte, dibujo y diseño.",
          ratings: "4.7/5",
          tags: ["Arte", "Historia", "Dibujo"],
        },
      ],
      languages: [
        {
          name: "Inglés",
          level: "B2",
        },
        {
          name: "Español",
          level: "Nativo",
        },
      ],
      portfolio: {
        title: "Portafolio",
        projects: [
          {
            title: "Branding | Congreso Internacional de Género",
            description: "Logotipo, imagen corporativa y voz y tono del proyecto",
            skills: ["Branding", "Género", "Logotipo", "Imagen corporativa"],
            website: "https://www.congresogeneroyeducacion.com",
            mediaLinks: ["https://www.congresogeneroyeducacion.com/images", "https://www.congresogeneroyeducacion.com/videos"],
          },
          {
            title: "Título del proyecto",
            description: "Descripción del proyecto",
            skills: ["Habilidades", "Sitio web"],
            mediaLinks: ["https://www.example.com/images", "https://www.example.com/videos"],
          },
        ],
      },
      reviews: [
        {
          name: "Elena R.",
          jobTitle: "Vendedor textil",
          rating: 3.4,
          pros: "Teletrabajo. Gestión del horario.",
          cons: "No enriquecerte de un gran equipo de trabajo.",
          review: "100% recomendable, E*** y Oscar unos crack. Pero el mayor punto a destacar la comunidad. Poder hablar con gente que está en una situación igual o parecida ayuda mucho a motivarse y seguir aprendiendo.",
          avatarUrl: "/path/to/avatar.jpg",
          timeAgo: "Hace 3 semanas"
        },
      ]
    },
  };

  // const candidateInfo = candidateData[candidateId ? parseInt(candidateId, 10) : 0];
  const candidateInfo = candidateData[1];

  return (
    <div>
      <Header />

      <div className="mx-2 md:mx-6 lg:mx-6 mt-4">
        <Link to="/offers/1" className="flex items-center text-[#006497] text-[16px] font-bold">
          <img src={icon} alt="Arrow Left" className="mr-2" />
          Volver al listado
        </Link>

        <div className="mt-4" style={{ height: '293px' }}>
          <div style={{ backgroundColor: '#81BFEC', height: '100%', borderRadius: '6px' }}></div>
        </div>

        <div className="flex justify-between items-center mt-6 -mb-2">
          <div className="flex items-center space-x-2">
            <span className="text-[#1A1A1A] text-[20px] font-normal opacity-70 -mt-1">Acciones de perfil:</span>
            <a href="/" className="text-[#00476D] font-bold text-[16px]">Añadir a favoritos</a>
            <span>|</span>
            <a href="/" className="text-[#00476D] font-bold text-[16px]">Avanzar en la fase</a>
            <span>|</span>
            <a href="/" className="text-[#00476D] font-bold text-[16px]">Guardar perfil</a>
            <span>|</span>
            <a href="/" className="text-[#DC2828] font-bold text-[16px]">Archivar candidato</a>
          </div>
          <Button
            type="primary"
            className="bg-[#006497] font-semibold text-[14px]"
            onClick={handleUnlockProfile}
          >
            {isProfileUnlocked ? 'Comenzar chat con el candidato' : 'Desbloquear Perfil'}
          </Button>
        </div>
      </div>

      {candidateInfo ? (
        <div style={{ border: '1px solid #81BFEC', borderRadius: '6px', padding: '16px', marginTop: '16px', marginLeft: '20px', marginRight: '20px' }}>
          <CandidateInfo
            imageUrl={candidateInfo.imageUrl}
            nombre={candidateInfo.nombre}
            puesto={candidateInfo.puesto}
            ubicacion={candidateInfo.ubicacion}
            habilidades={candidateInfo.habilidades}
            descripcion={candidateInfo.descripcion}
            radarData={candidateInfo.radarData}
            isProfileUnlocked={isProfileUnlocked}
          />

          <div className="mt-6">
            <h2 className="text-lg font-semibold">Valoraciones</h2>
            <div className="flex items-center mt-2">
              <RatingBlue
                filledStars={3.4}
                filledStarSize={{ width: '24px', height: '24px', marginRight: '2px', marginTop: '2px' }}
                emptyStarSize={{ width: '24px', height: '24px', marginLeft: '-2px' }}
              />
              <span className="ml-2 text-sm text-blue-600">12 valoraciones</span>
            </div>
            <Carousel>
              {candidateInfo.reviews.map((review, index) => (
                <ReviewCard key={index} {...review} />
              ))}
            </Carousel>
          </div>

          <LaboralExp
            experiences={candidateInfo.experiences}
            education={candidateInfo.education}
            languages={candidateInfo.languages}
          />

          <Portfolio title={candidateInfo.portfolio.title} projects={candidateInfo.portfolio.projects} />
        </div>
      ) : (
        <div>No se encontró información del candidato.</div>
      )}
    </div>
  );
};

export default CandidateInformation;
