import React, { useState } from 'react';
import {
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Radar,
  Legend,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import ValuationModal from '../../../../components/pages/modals/ModalValoraPerfil';  // Importa el modal
import CustomLegend from './Legend';

interface Skill {
  subject: string;
  A: number;
  B: number;
}

interface CandidateInfoProps {
  imageUrl: string;
  nombre: string;
  puesto: string;
  ubicacion: string;
  habilidades: string[];
  descripcion: string;
  radarData: Skill[];
  isProfileUnlocked: boolean;
}

const CandidateInfo: React.FC<CandidateInfoProps> = ({
  imageUrl,
  nombre,
  puesto,
  ubicacion,
  habilidades,
  descripcion,
  radarData,
  isProfileUnlocked,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false); // Estado para el modal

  const showModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const data = [
    { subject: 'Colaborativo', A: 7, B: 6, fullMark: 10 },
    { subject: 'Innovador', A: 6, B: 5, fullMark: 10 },
    { subject: 'Detallista', A: 5, B: 6, fullMark: 10 },
    { subject: 'Proactivo', A: 4, B: 4, fullMark: 10 },
    { subject: 'Adaptable', A: 6, B: 5, fullMark: 10 },
    { subject: 'Energético', A: 3, B: 4, fullMark: 10 },
    { subject: 'Empático', A: 2, B: 3, fullMark: 10 },
    { subject: 'Creativo', A: 7, B: 8, fullMark: 10 },
    { subject: 'Analítico', A: 5, B: 6, fullMark: 10 },
  ];

  return (
    <div className="flex justify-between">
      <div className="w-1/2">
        <div className="flex flex-col items-start">
          <h2 className="text-[22px] font-bold mb-5">Perfil</h2>
          <div
            className={`relative ${isProfileUnlocked ? '' : 'border border-[#006497] rounded-md p-6'}`}
          >
            {!isProfileUnlocked && (
              <div className="absolute inset-0 bg-white bg-opacity-75 flex items-center justify-center z-10">
                <button className="border border-blue-500 text-blue-500 px-4 py-2 rounded">
                  Desbloquear perfil
                </button>
              </div>
            )}

            <div className="flex items-center mb-4">
              <img
                src={imageUrl}
                alt="Perfil"
                className="w-40 h-40 mr-4"
              />
              <div>
                <h2 className="text-lg font-regular">{nombre}</h2>
                <p className="text-base text-gray-500 font-bold">{puesto}</p>
                <p className="text-base text-gray-500">{ubicacion}</p>
                <button
                  className="border border-blue-500 text-blue-500 px-4 py-2 rounded"
                  onClick={showModal}  // Abre el modal al hacer clic
                >
                  Valorar candidato
                </button>
              </div>
            </div>
          </div>

          <div className="mt-4">
            <h3 className="font-bold">Habilidades</h3>
            <div className="flex flex-wrap gap-2 mt-2">
              {habilidades.map((habilidad, index) => (
                <span
                  key={index}
                  className="bg-[#FCFCFC] border border-[#E1E1E2] rounded-full px-3 py-1 text-[16px] font-semibold text-black"
                >
                  {habilidad}
                </span>
              ))}
            </div>
          </div>

          <div className="mt-4">
            <h3 className="font-bold mb-4">Sobre mí</h3>
            <p className="text-gray-700 overflow-hidden" style={{ display: '-webkit-box', WebkitLineClamp: 3, WebkitBoxOrient: 'vertical', whiteSpace: 'normal', textOverflow: 'ellipsis' }}>
              {descripcion}
            </p>
          </div>
        </div>
      </div>

      <div className="w-1/2 flex justify-center items-start relative">
        <h3 className="font-bold text-lg absolute top-0 right-0">Soft skills</h3>
        <div className="mt-8">
          <div style={{ position: 'absolute', top: '20px', left: 0, width: '100%', height: '100%', zIndex: 2 }}>
            <ResponsiveContainer width="100%" height={350}>
              <RadarChart cx="50%" cy="50%" outerRadius="80%" data={data}>
                <PolarGrid />
                <PolarAngleAxis
                  dataKey="subject"
                  tick={{ fontSize: 12, fill: '#000', fontWeight: 'bold' }}
                />
                <PolarRadiusAxis angle={30} domain={[0, 10]} />
                <Radar name="Perfil del Candidato" dataKey="A" stroke="#006497" fill="transparent" />
                <Radar name="Perfil de la oferta" dataKey="B" stroke="#FDBCB4" fill="transparent" />
                <Legend content={<CustomLegend />} />
                <Tooltip />
              </RadarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>

      {/* Modal para "Valorar candidato" */}
      <ValuationModal
        visible={isModalVisible}
        onClose={closeModal}  // Asegúrate de usar la función correcta aquí
        entityName={nombre}   // Cambié 'name' a 'nombre' para que coincida con el prop de CandidateInfoProps
        entityType="candidate"
      />
    </div>
  );
};

export default CandidateInfo;
