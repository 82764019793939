import React, { useState, useEffect } from "react";
import { Modal, Button } from "antd";
import IconClosed from "../../../../assets/icons/IconClosed.svg";
import IconDelete from '../../../../assets/icons/IconDelete.svg';
import { PlusOutlined } from "@ant-design/icons";
import ModalAddL from "./ModalAddLanguages";
import ModalDeleteLanguages from "./ModalDeleteLanguages";
import ModalConfirm from "./ModalConfirm";

interface ModalEditLanguagesProps {
  visible: boolean;
  onClose: () => void;
  languages: { name: string; level: string }[];
}

const ModalEditLanguages: React.FC<ModalEditLanguagesProps> = ({ visible, onClose, languages: initialLanguages = [] }) => {
  const [languages, setLanguages] = useState<any>(initialLanguages);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [isAddLanguageModalVisible, setIsAddLanguageModalVisible] = useState(false);
  const [isDeleteLanguageModalVisible, setIsDeleteLanguageModalVisible] = useState(false);
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const [languageToDelete, setLanguageToDelete] = useState<{ name: string; level: string } | null>(null);

  useEffect(() => {
    setLanguages(initialLanguages || []);
  }, [initialLanguages]);

  const openAddLanguageModal = () => {
    setIsAddLanguageModalVisible(true);
  };

  const handleAddLanguageModalClose = () => {
    setIsAddLanguageModalVisible(false);
  };

  const openDeleteLanguageModal = (language: { name: string; level: string }) => {
    setLanguageToDelete(language);
    setIsDeleteLanguageModalVisible(true);
  };

  const handleDeleteLanguageModalClose = () => {
    setIsDeleteLanguageModalVisible(false);
    setLanguageToDelete(null);
  };

  const handleDeleteLanguage = () => {
    if (languageToDelete) {
      const updatedLanguages = languages.filter((lang: any) => lang.name !== languageToDelete.name);
      setLanguages(updatedLanguages);
      setIsSubmitDisabled(false);
      setIsDeleteLanguageModalVisible(false);
      setIsConfirmModalVisible(true); // Muestra el modal de confirmación
    }
  };

  const handleSaveChanges = () => {
    if (!isSubmitDisabled) {
      onClose();
    }
  };

  return (
    <>
      <Modal
        open={visible && !isAddLanguageModalVisible && !isDeleteLanguageModalVisible && !isConfirmModalVisible}
        onCancel={onClose}
        footer={null}
        centered
        closable={false}
        width={677}
        bodyStyle={{ borderRadius: "12px" }}
        style={{ borderRadius: "12px", border: "1px solid #E1E1E2", marginTop: '15px', marginBottom: '15px' }}
      >
        <img
          src={IconClosed}
          alt="Cerrar"
          onClick={onClose}
          style={{
            position: "absolute",
            top: "34px",
            right: "34px",
            cursor: "pointer",
            width: "24px",
            height: "24px",
          }}
        />

        <div className="text-center mx-[86px] mb-[px] mt-[23px]">
          <h3 className="mt-[16px] mx-[44px] text-heading-md font-bold">
            Editar Idiomas
          </h3>
          <p className="font-medium px-[55px] text-body-sm mt-[10px]">
            Por favor, a continuación edita tu información
          </p>
        </div>

        <div className="mx-[47px] mb-[36px]">
          <span className="text-body-md font-semibold">Idiomas</span>
          <ul className="my-[26px]">
            {languages.map((language: any, index: number) => (
              <li key={index} className="flex justify-between items-center my-[26px]">
                <span className="text-body-md font-normal">{language.name} - {language.level}</span>
                <img
                  src={IconDelete}
                  alt="Eliminar"
                  onClick={() => openDeleteLanguageModal(language)}
                  style={{
                    cursor: "pointer",
                    width: "24px",
                    height: "24px",
                  }}
                />
              </li>
            ))}
          </ul>
          <div className="flex justify-center">
            <Button className="text-blue3 font-semibold border border-white w-[232px] h-[36px] principal-nav-notify-buttonS flex items-center justify-center" onClick={openAddLanguageModal}>
              <PlusOutlined />
              Añadir idioma
            </Button>
          </div>
        </div>

        <div className="flex justify-center mt-[40px] mb-[35px]">
          <Button
            onClick={onClose}
            className="principal-nav-notify-buttonG w-[118px] h-[44px]"
            style={{ marginRight: "8px", borderRadius: "4px" }}
          >
            Cancelar
          </Button>
          <Button
            disabled={isSubmitDisabled}
            onClick={handleSaveChanges}
            className={`w-[181px] h-[44px] ml-[18px] rounded-[4px] ${isSubmitDisabled ? 'bg-[#F4F4F5] text-[#757575] font-semibold text-body-md cursor-not-allowed' : 'bg-blue3 principal-nav-notify-button2 text-white cursor-pointer'}`}
          >
            Guardar cambios
          </Button>
        </div>
      </Modal>

      {/* Modal para añadir idiomas */}
      <ModalAddL
        visible={isAddLanguageModalVisible}
        onClose={handleAddLanguageModalClose}
        onAdd={(newLanguage) => {
          setLanguages([...languages, newLanguage]);
          setIsSubmitDisabled(false);
          handleAddLanguageModalClose(); // Cerrar el modal de añadir idiomas y reabrir el modal de edición de idiomas
        }}
      />

      {/* Modal para confirmar eliminación de idiomas */}
      <ModalDeleteLanguages
        visible={isDeleteLanguageModalVisible}
        onClose={handleDeleteLanguageModalClose}
        language={languageToDelete ? languageToDelete.name : null} // Pasar el idioma al modal
        level={languageToDelete ? languageToDelete.level : null} // Pasar el nivel al modal
        onConfirmDelete={handleDeleteLanguage} // Manejar la eliminación
      />

      {/* Modal de confirmación después de eliminar */}
      <ModalConfirm
        visible={isConfirmModalVisible}
        onClose={() => setIsConfirmModalVisible(false)} // Cierra el modal de confirmación
        message={languageToDelete ? `${languageToDelete.name} - ${languageToDelete.level}` : ""}
      />
    </>
  );
};

export default ModalEditLanguages;
