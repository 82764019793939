import React, { useState } from "react";
import { Typography } from "antd";
import "tailwindcss/tailwind.css";
import IconUpload from '../../../assets/icons/IconUpload.svg';
import IconEdit from '../../../assets/icons/EditP.svg';
import ModalCVSelect from './Modals/ModalCVSelect';

const { Text } = Typography;

interface Project {
  name: string;
  description: string;
  skills: string;
  link: string;
  logoUrl: string;
}

interface MyCurriculumProps {
  projects: Project[];
}

const MyCurriculum: React.FC<MyCurriculumProps> = ({ projects }) => {
  const [uploadedFileName, setUploadedFileName] = useState<string | null>(null);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false); // Estado para manejar la visibilidad del modal

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setUploadedFileName(file.name);
    }
  };

  const handleEditClick = () => {
    setIsModalVisible(true); // Muestra el modal cuando se hace clic en el botón de editar
  };

  const handleModalClose = () => {
    setIsModalVisible(false); // Oculta el modal cuando se cierra
  };

  return (
    <div className="pb-8">
      <h1 className="text-heading-md font-bold pb-3 flex items-center">
        Mi Curriculum
        {!uploadedFileName ? (
          <label htmlFor="file-upload" className="pl-3 cursor-pointer">
            <img
              src={IconUpload}
              alt="Cargar"
              className="inline-block text-sky-blue0 w-[35px] pb-[4px]"
            />
          </label>
        ) : (
          <img
            src={IconEdit}
            alt="Editar"
            className=" ml-3 cursor-pointer inline-block text-sky-blue0 w-[35px] pb-[4px]"
            onClick={handleEditClick}
          />
        )}
        <input
          id="file-upload"
          type="file"
          accept=".pdf,.doc,.docx"
          onChange={handleFileChange}
          style={{ display: 'none' }}
        />
      </h1>
      {uploadedFileName && (
        <div className="mt-2">
          <Text className="font-bold text-heading-x1">{uploadedFileName}</Text>
        </div>
      )}

      {/* ModalCVSelect */}
      <ModalCVSelect
        visible={isModalVisible}
        onClose={handleModalClose}
      />
    </div>
  );
};

export default MyCurriculum;
