import React from "react";
import { Layout } from "antd";
import ButtonText from '../../button/ButtonText';
import 'tailwindcss/tailwind.css';

const { Sider } = Layout;

interface SidebarOffersProps {
  inDrawer?: boolean; // Prop para determinar si está en el Drawer
  title?: string; // Nueva prop para el título dinámico
}

const SidebarOffers: React.FC<SidebarOffersProps> = ({ inDrawer = false, title = "Tus ofertas de empleo" }) => {
  const buttonsOpenOffers = [
    { type: 'link', label: 'Reponedor de supermercado', size: 'small', textColor: '#006497', color: 'white'},
    { type: 'link', label: 'Mozo de almacén', size: 'small', textColor: '#006497', color: 'white' },
    { type: 'link', label: 'Administrativo', size: 'small', textColor: '#006497', color: 'white' },
  ];

  const buttonsClosedOffers = [
    { type: 'link', label: 'Reponedor de supermercado', size: 'small', textColor: '#006497', color: 'white' },
    { type: 'link', label: 'Mozo de almacén', size: 'small', textColor: '#006497', color: 'white' },
    { type: 'link', label: 'Administrativo', size: 'small', textColor: '#006497', color: 'white' },
  ];

  const buttonsSavedOffers = [
    { type: 'link', label: 'Cajero', size: 'small', textColor: '#006497', color: 'white' },
  ];

  const buttonsManageOffers = [
    { type: 'link', label: '¿Pricing?', size: 'small', textColor: '#006497', color: 'white'},
    { type: 'link', label: '¿Archivadas?', size: 'small', textColor: '#006497', color: 'white'},
    { type: 'link', label: '¿Compartidas?', size: 'small', textColor: '#006497', color: 'white'},
  ];

  return (
    <div className="flex min-h-screen">
      <Sider
        width={227}
        style={{ backgroundColor: "#fff" }}
        className={` ${inDrawer ? 'p-[5px] border-none' : 'p-[24px] rounded-lg shadow-lg border-2 border-sky-blue0'}`} // Aplica borde solo si no está en el Drawer
      >
        <h2 className="text-heading-md text-green42 font-bold mb-[26px]">
          {title} {/* Usar el título dinámico */}
        </h2>
        
        <div className="mb-6">
          <ButtonText
            buttons={[
              { 
                type: 'link', 
                label: 'Crear una nueva oferta de empleo', 
                size: 'small', 
                textColor: '#006497', 
                color: 'white', 
                fontWeight: 'bold', 
                fontSize: '14px', 
                minHeight: '40px' // Set specific minHeight for this button
              },
            ]}
            vertical
            gap={4}
          />
        </div>

        <div className="mb-[24px]">
          <h3 className="font-bold text-green42 text-caption">Ofertas abiertas</h3>
          <ButtonText buttons={buttonsOpenOffers} vertical/>
        </div>
        
        <div className="mb-[24px]">
          <h3 className="font-bold text-green42 text-caption">Ofertas finalizadas</h3>
          <ButtonText buttons={buttonsClosedOffers} vertical/>
        </div>
        
        <div className="mb-[24px]">
          <h3 className="font-bold text-green42 text-caption">Ofertas guardadas</h3>
          <ButtonText buttons={buttonsSavedOffers} vertical/>
        </div>

        <div className="mb-[24px]">
          <h3 className="font-bold text-green42 text-caption">Gestionar tus ofertas</h3>
          <ButtonText buttons={buttonsManageOffers} vertical/>
        </div>
      </Sider>
    </div>
  );
};

export default SidebarOffers;
