import React from 'react';
import { Checkbox } from 'antd';
import '../styles/checkboxProps/CheckboxProps.css';

interface StyledCheckboxProps {
  value?: number;
  children?: React.ReactNode;
  className?: string;
  onChange?: (e: any) => void;
}

const StyledCheckbox: React.FC<StyledCheckboxProps> = ({ value, children, className, onChange }) => (
  <div className={className}>
    <Checkbox
      value={value}
      className="custom-checkbox"
      onChange={onChange}
    >
      {children}
    </Checkbox>
  </div>
);

export default StyledCheckbox;