import React from 'react';

interface Experience {
  title: string;
  company: string;
  location: string;
  startDate: string;
  endDate: string;
  totalDuration: string;
  description: string;
  ratings: string;
  tags: string[];
}

interface Education {
  title: string;
  institution: string;
  location: string;
  startDate: string;
  endDate: string;
  totalDuration: string;
  description: string;
  ratings: string;
  tags: string[];
}

interface Language {
  name: string;
  level: string;
}

interface LaboralExpProps {
  experiences: Experience[];
  education: Education[];
  languages: Language[];
}

const LaboralExp: React.FC<LaboralExpProps> = ({ experiences, education, languages }) => {
  return (
    <div className="container mt-6">
      <div className="grid grid-cols-1 gap-8"> 
        {/* Columna única para los títulos */}
        <div>
          <h3 className="text-xl font-bold mb-4">Experiencia laboral</h3>
          <div className="grid grid-cols-4 gap-4">
            {experiences.map((exp, index) => (
              <div key={index} className="col-span-1 mb-6">
                <h4 className="text-lg font-semibold">{exp.title}</h4>
                <p>{exp.company} | {exp.location}</p>
                <p>Inicio: {exp.startDate} | Fin: {exp.endDate} | Total: {exp.totalDuration}</p>
                <p>Descripción del puesto: {exp.description}</p>
                <p>Valoraciones: {exp.ratings}</p>
                <p>Etiquetas: {exp.tags.join(', ')}</p>
              </div>
            ))}
          </div>
        </div>

        <div>
          <h3 className="text-xl font-bold mb-4">Educación</h3>
          <div className="grid grid-cols-4 gap-4">
            {education.map((edu, index) => (
              <div key={index} className="col-span-1 mb-6">
                <h4 className="text-lg font-semibold">{edu.title}</h4>
                <p>{edu.institution} | {edu.location}</p>
                <p>Inicio: {edu.startDate} | Fin: {edu.endDate} | Total: {edu.totalDuration}</p>
                <p>Descripción del puesto: {edu.description}</p>
                <p>Valoraciones: {edu.ratings}</p>
                <p>Etiquetas: {edu.tags.join(', ')}</p>
              </div>
            ))}
          </div>
        </div>

        <div>
          <h3 className="text-xl font-bold mb-4">Idiomas</h3>
          <div className="grid grid-cols-4 gap-4">
            {languages.map((lang, index) => (
              <div key={index} className="col-span-1 mb-6">
                <h4 className="text-lg font-semibold">{lang.name} <span className="font-bold">{lang.level}</span></h4>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LaboralExp;
